<template>
  <div>
    <span class="required draftSaved" v-if="!this.wizardData.isCompleted">
      <span class="material-icons">done</span>
      <span>{{ $t("checkCoverage.draftSaved") }}</span>
    </span>

    <h2>{{ $t("checkCoverage.titleReferences") }}*</h2>
    <p class="subTitle" v-if="false">
      {{ $t("checkCoverage.subTitleReferences") }}
    </p>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--4-col">
        <span class="labelOne marginLeft8"><br /></span>
        <select
          class="endConsumer"
          name="end_customer"
          @change="submit($event)"
          @input="label($event)"
          style="margin-left: 0"
        >
          <option value="">{{ $t("checkCoverage.customerEnd") }}</option>
          <option value="9999">{{ $t("checkCoverage.add") }}</option>
          <option
            v-for="(singleCustomer, key) in this.end_customers"
            :value="key"
            :key="key"
          >
            {{ singleCustomer.company_name }}
          </option>
        </select>
        <span
          class="error"
          v-if="
            this.v$.end_customer.company_name.$invalid &&
            this.v$.end_customer.company_name.$dirty
          "
        >
          {{ $t("checkCoverage.customerEnd") }}
          {{ $t("checkCoverage.isRequired") }}
        </span>
      </div>
      <div class="mdl-cell mdl-cell--4-col">
        <div class="cdl-input">
          <input
            type="text"
            @input="label($event)"
            v-model="end_customer.vat_id"
            name="endCustomerVatId"
            @change="submit($event)"
            id="endCustomerVatId"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.customerEndPIva") }}</label>
          <p
            class="error"
            v-if="
              this.v$.end_customer.vat_id.$invalid &&
              this.v$.end_customer.vat_id.$dirty
            "
          >
            {{ $t("checkCoverage.customerEndPIvaRequired") }}
          </p>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--4-col">
        <div class="cdl-input">
          <input
            type="text"
            @input="label($event)"
            v-model="end_customer.cf"
            name="endCustomerVatCf"
            @change="submit($event)"
            id="endCustomerVatCf"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.customerEndFiscalCode") }}</label>
          <p
            class="error"
            v-if="
              this.v$.end_customer.cf.$invalid && this.v$.end_customer.cf.$dirty
            "
          >
            {{ $t("checkCoverage.customerEndFiscalCodeRequired") }}
          </p>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--12-col" v-if="viewNewCustomerEnd">
        <div class="cdl-input" style="width: 97%">
          <input
            type="text"
            @input="
              label($event);
              saveCompanyName();
            "
            v-model="end_customer.company_name_new"
            name="endCustomerEndNew"
            id="endCustomerEndNew"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.customer") }}</label>
          <p
            class="error"
            v-if="
              this.v$.end_customer.company_name_new.$invalid &&
              this.v$.end_customer.company_name_new.$dirty
            "
          >
            {{ $t("checkCoverage.customerEnd") }}
            {{ $t("checkCoverage.isRequired") }}
          </p>
        </div>
      </div>
    </div>

    <hr />

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <span class="labelTwo">{{ $t("checkCoverage.techRef") }}*</span>
      </div>
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalName"
            v-model="contacts[0].name"
            @change="submit"
            @input="label($event)"
            id="technicalName"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.nameSurname") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[0].name.$invalid &&
              this.v$.contacts[0].name.$dirty
            "
          >
            {{ $t("checkCoverage.nameSurnameTechRefRequired") }}
          </span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col"></div>
    </div>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalPhone"
            v-model="contacts[0].phone"
            @change="submit"
            @keyup="onlyNumbers(0)"
            @input="label($event)"
            pattern="/^[+\s\]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g"
            id="technicalPhone"
          />
          <label>{{ $t("checkCoverage.mobile") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[0].phone.$invalid &&
              this.v$.contacts[0].phone.$dirty &&
              this.v$.contacts[0].phone.$errors[0].$params.type == 'integer'
            "
          >
            {{ $t("checkCoverage.nAnPhone") }}
          </span>
          <span
            class="error"
            v-if="
              this.v$.contacts[0].phone.$invalid &&
              this.v$.contacts[0].phone.$dirty &&
              this.v$.contacts[0].phone.$errors[0].$params.type != 'integer'
            "
          >
            {{ $t("checkCoverage.mobileTechRefRequired") }}
          </span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalEmail"
            v-model="contacts[0].email"
            @change="submit"
            @input="label($event)"
            id="technicalEmail"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.email") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[0].email.$invalid &&
              this.v$.contacts[0].email.$dirty
            "
          >
            {{ $t("checkCoverage.emailTechRefRequired") }}
          </span>
        </div>
      </div>
    </div>

    <hr />

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <span class="labelTwo"
          >{{ $t("checkCoverage.administratorRef") }}*</span
        >
      </div>
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalName"
            v-model="contacts[1].name"
            @change="submit"
            @input="label($event)"
            id="administratorName"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.nameSurname") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[1].name.$invalid &&
              this.v$.contacts[1].name.$dirty
            "
          >
            {{ $t("checkCoverage.nameSurnameAdministratorRefRequired") }}
          </span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col"></div>
    </div>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalPhone"
            v-model="contacts[1].phone"
            @change="submit"
            @keyup="onlyNumbers(1)"
            @input="label($event)"
            id="administratorPhone"
          />
          <label>{{ $t("checkCoverage.mobile") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[1].phone.$invalid &&
              this.v$.contacts[1].phone.$dirty &&
              this.v$.contacts[1].phone.$errors[0].$params.type == 'integer'
            "
          >
            {{ $t("checkCoverage.nAnPhone") }}
          </span>
          <span
            class="error"
            v-if="
              this.v$.contacts[1].phone.$invalid &&
              this.v$.contacts[1].phone.$dirty &&
              this.v$.contacts[1].phone.$errors[0].$params.type != 'integer'
            "
          >
            {{ $t("checkCoverage.mobileAdministratorRefRequired") }}
          </span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalEmail"
            v-model="contacts[1].email"
            @change="submit"
            @input="label($event)"
            id="administratorEmail"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.email") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[1].email.$invalid &&
              this.v$.contacts[1].email.$dirty
            "
          >
            {{ $t("checkCoverage.emailAdministratorRefRequired") }}
          </span>
        </div>
      </div>
    </div>

    <hr />

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <span class="labelTwo">{{ $t("checkCoverage.onSiteRef") }}*</span>
      </div>
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalName"
            v-model="contacts[2].name"
            @change="submit"
            @input="label($event)"
            id="onSiteName"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.nameSurname") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[2].name.$invalid &&
              this.v$.contacts[2].name.$dirty
            "
          >
            {{ $t("checkCoverage.nameSurnameOnSiteRefRequired") }}
          </span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col"></div>
    </div>

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalPhone"
            v-model="contacts[2].phone"
            @change="submit"
            @keyup="onlyNumbers(2)"
            @input="label($event)"
            id="onSitePhone"
          />
          <label>{{ $t("checkCoverage.mobile") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[2].phone.$invalid &&
              this.v$.contacts[2].phone.$dirty &&
              this.v$.contacts[2].phone.$errors[0].$params.type == 'integer'
            "
          >
            {{ $t("checkCoverage.nAnPhone") }}
          </span>
          <span
            class="error"
            v-if="
              this.v$.contacts[2].phone.$invalid &&
              this.v$.contacts[2].phone.$dirty &&
              this.v$.contacts[2].phone.$errors[0].$params.type != 'integer'
            "
          >
            {{ $t("checkCoverage.mobileOnSiteRefRequired") }}
          </span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col">
        <div class="cdl-input">
          <input
            type="text"
            name="technicalEmail"
            v-model="contacts[2].email"
            @change="submit"
            @input="label($event)"
            id="onSiteEmail"
            maxlength="255"
          />
          <label>{{ $t("checkCoverage.email") }}</label>
          <span
            class="error"
            v-if="
              this.v$.contacts[2].email.$invalid &&
              this.v$.contacts[2].email.$dirty
            "
          >
            {{ $t("checkCoverage.emailOnSiteRefRequired") }}
          </span>
        </div>
      </div>
    </div>

       <hr />

       <div class="mdl-grid">
         <div class="mdl-cell mdl-cell--12-col">
           <span class="labelTwo">{{ $t("remoteHands.infoOrder") }}</span>
         </div>
         <div class="mdl-cell mdl-cell--6-col">
           <div class="cdl-input">
             <input
               type="text"
               name="client_po"
               v-model="client_po"
               @change="submit"
               @input="label($event)"
               maxlength="255"
             />
             <label>{{ $t("checkCoverage.orderRef") }}</label>
           </div>
         </div>
         <div class="mdl-cell mdl-cell--6-col"></div>
       </div>

    <hr />

    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <label class="labelOne">{{
          updateInfo
            ? $t("checkCoverage.updateUser")
            : $t("checkCoverage.saveUser")
        }}</label>
      </div>
      <div class="mdl-cell mdl-cell--1-col">
        <p class="labelSwitch" :class="{ onNo: !this.saveUser }">
          {{ $t("shipping.no") }}
        </p>
      </div>
      <div class="mdl-cell mdl-cell--1-col">
        <label class="switch" @change="setUser()">
          <input type="checkbox" />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="mdl-cell mdl-cell--1-col">
        <p class="labelSwitch" :class="{ onYes: this.saveUser }">
          {{ $t("shipping.yes") }}
        </p>
      </div>
    </div>

    <div v-if="viewButtons">
      <div class="cdlContentButtonDouble">
        <div class="cdlButtonSingle width170" @click="goBack()">
          {{ $t("checkCoverage.stepsButtonTwo") }}
        </div>
        <div
          id="goNext"
          class="cdlButtonSingle width170"
          @click="goNext(false)"
        >
          {{ $t("checkCoverage.stepsButtonOne") }}
        </div>
        <div
          id="buttonCompleteRelatedServices"
          class="cdlButtonSingle"
          @click="goNext(true)"
          v-if="this.wizardData.isCompleted"
        >
          {{ $t("checkCoverage.goEnd") }}
        </div>
      </div>
      <p
        class="deleteOrder"
        v-if="!this.wizardData.isCompleted"
        @click="cancelOrder()"
      >
        {{ $t("checkCoverage.stepsButtonThree") }}
      </p>
    </div>
    <MyLoading :viewLoader="viewLoaderCustomers" type="" />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { email, integer, required, requiredIf } from "@vuelidate/validators";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "MyReference",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      end_customer: {
        company_name_new: {
          requiredIfFoo: requiredIf(this.viewNewCustomerEnd),
        },
        company_name: { required },
        vat_id: { requiredIfFoo: requiredIf(!this.end_customer.cf) },
        cf: { requiredIfFoo: requiredIf(!this.end_customer.vat_id) },
      },
      contacts: [
        {
          name: { required },
          email: { required, email },
          phone: { required, integer },
        },
        {
          name: { required },
          email: { required, email },
          phone: { required, integer },
        },
        {
          name: { required },
          email: { required, email },
          phone: { required, integer },
        },
      ],
    };
  },
  props: {
    wizardData: {
      type: Object,
      required: true,
    },
    stepData: {
      type: Object,
      required: true,
    },
  },
  components: {
    MyLoading,
  },
  data() {
    return {
      end_customers: [],
      end_customer: this.wizardData.end_customer,
      client_po: this.wizardData.client_po,
      contacts: [
        {
          type: "technical",
          name: null,
          email: null,
          phone: null,
        },
        {
          type: "administrative",
          name: null,
          email: null,
          phone: null,
        },
        {
          type: "on-site",
          name: null,
          email: null,
          phone: null,
        },
      ],
      viewNewCustomerEnd: false,
      saveUser: false,
      updateInfo: false,
      viewLoaderCustomers: true,
      viewButtons: true,
    };
  },
  methods: {
    submit(ev) {
      this.contactsBase = [
        {
          type: "technical",
          name: "",
          email: "",
          phone: "",
        },
        {
          type: "administrative",
          name: "",
          email: "",
          phone: "",
        },
        {
          type: "on-site",
          name: "",
          email: "",
          phone: "",
        },
      ];

      if (
        Object.keys(this.end_customer).length > 0 &&
        ev.srcElement.classList.contains("endConsumer")
      ) {
        if (ev.srcElement.value == "9999" || ev.srcElement.value == "") {
          this.updateInfo = false;
          this.end_customer.vat_id = null;
          this.end_customer.cf = null;
          this.viewNewCustomerEnd = false;
          if (ev.srcElement.value == "9999") {
            this.viewNewCustomerEnd = true;
          }
          this.end_customer.company_name = "";
          this.contacts = this.contactsBase;
          // this.contacts[0].name = null;
          // this.contacts[0].email = null;
          // this.contacts[0].phone = null;
          // this.contacts[1].name = null;
          // this.contacts[1].email = null;
          // this.contacts[1].phone = null;
          // this.contacts[2].name = null;
          // this.contacts[2].email = null;
          // this.contacts[2].phone = null;
          return;
        } else {
          this.end_customer.company_name =
            this.end_customers[ev.srcElement.value];
          this.updateInfo = true;
          this.viewNewCustomerEnd = false;
          this.end_customer.vat_id = this.end_customer.company_name
            ? this.end_customer.company_name.vat_id
            : null;
          this.end_customer.cf = this.end_customer.company_name
            ? this.end_customer.company_name.cf
            : null;
          this.contacts =
            this.end_customers[ev.srcElement.value].contacts ??
            this.contactsBase;
          this.contacts =
            this.end_customer.company_name &&
            this.end_customer.company_name.contacts
              ? this.end_customer.company_name.contacts
              : this.contacts;
          if (this.end_customer.company_name_new) {
            this.end_customer.company_name_new = "";
          }

          if (this.contacts.length < this.contactsBase.length) {
            this.contactsBase.forEach((elm) => {
              if (!this.contacts.find((o) => o.type === elm.type)) {
                this.contacts.push(elm);
              }
            });

            // order
            let tmpContacts = [];
            this.contacts.forEach((elm) => {
              if (elm.type == "technical") {
                tmpContacts[0] = elm;
              }
              if (elm.type == "administrative") {
                tmpContacts[1] = elm;
              }
              if (elm.type == "on-site") {
                tmpContacts[2] = elm;
              }
            });

            this.contacts = tmpContacts;
          }
        }
      }
      this.ctrlLabels();

      this.$emit("update", {
        contacts: this.contacts,
        end_customer: this.end_customer,
        client_po: this.client_po,
      });
    },
    goBack() {
      this.$emit("goBack");
    },
    async goNext(goSummary = null) {
      const result = await this.v$.$validate();
      if (result) {
        this.v$.$reset();
        if (this.saveUser) {
          this.viewLoaderCustomers = false;
          this.viewButtons = false;
          let he = {
            Accept: "application/json",
            Authorization: "Bearer " + this.$keycloak.idToken,
          };

          let data = {};

          if (this.updateInfo) {
            data.company_name = this.end_customer.company_name.company_name;
          } else {
            data.company_name = this.end_customer.company_name;
          }

          data.contacts = this.contacts;

          if (this.end_customer.vat_id != null) {
            data.vat_id = this.end_customer.vat_id;
          }

          if (this.end_customer.cf != null) {
            data.cf = this.end_customer.cf;
          }

          let id = this.end_customer.company_name.id;
          if (!id) {
            id = this.end_customer.id;
          }

          if (this.updateInfo) {
            this.axios
              .put(
                this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                  "sales/v1/end-customer/" +
                  id,
                data,
                { headers: he }
              )
              .then(() => {
                this.viewLoaderCustomers = true;
                this.viewButtons = true;
                this.$emit("goNext", { goSummary });
              })
              .catch(function (err) {
                console.log(err);
              });
          } else {
            this.axios
              .post(
                this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
                  "sales/v1/end-customer",
                data,
                { headers: he }
              )
              .then(() => {
                this.viewLoaderCustomers = true;
                this.viewButtons = true;
                this.$emit("goNext", { goSummary });
              })
              .catch(function (err) {
                console.log(err);
                this.viewLoaderCustomers = true;
                this.viewButtons = true;
              });
          }
        } else {
          this.$emit("goNext", { goSummary });
        }
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      // window.scrollTo(0, 0);
      return;
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    ctrlLabels() {
      let allInputs = document.getElementsByTagName("input");
      setTimeout(() => {
        for (let i = 0; i < allInputs.length; i++) {
          if (allInputs[i].value != "") {
            allInputs[i].classList.add("not-empty");
          }
        }
      }, 500);
    },
    cancelOrder() {
      this.$emit("close");
    },
    getEndCustomers() {
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "sales/v1/end-customer",
          { headers: he }
        )
        .then((response) => {
          this.end_customers = response.data;
          this.buildCustomers();
        });
    },
    getStep(type) {
      let step = this.stepData;
      if (type == "before") {
        return step - 1;
      }
      return step + 1;
    },
    async onlyNumbers(index) {
      await this.v$.contacts[index].phone.$validate();
      // let ctrlLastChar = /^\d|[+]+$/.test(this.contacts[index].phone.slice(-1));
      // if( !ctrlLastChar ) {
      //   this.contacts[index].phone = this.contacts[index].phone.substring(0, this.contacts[index].phone.length - 1);
      // }
    },
    setUser() {
      this.saveUser = !this.saveUser;
    },
    buildCustomers() {
      if (localStorage.getItem("cdlOrderNetworkCoverage")) {
        let recoveryValue = JSON.parse(
          localStorage.getItem("cdlOrderNetworkCoverage")
        );
        if (recoveryValue.form.contacts.length > 0) {
          this.contacts = recoveryValue.form.contacts;
        }

        if (recoveryValue.form.end_customer != null) {
          this.end_customer.company_name =
            recoveryValue.form.end_customer.company_name;
          this.end_customer.vat_id = recoveryValue.form.end_customer.vat_id;
          this.end_customer.cf = recoveryValue.form.end_customer.cf;

          setTimeout(() => {
            let options = document
              .getElementsByClassName("endConsumer")[0]
              .getElementsByTagName("option");

            if (this.end_customer.company_name != null) {
              if (
                this.end_customer.company_name_new &&
                this.end_customer.company_name_new != ""
              ) {
                this.viewNewCustomerEnd = true;
                // this.end_customer.company_name.company_name = this.end_customer.company_name_new?? null;
                if (document.getElementsByClassName("endConsumer")[0]) {
                  document.getElementsByClassName("endConsumer")[0].value =
                    "9999";
                }
                this.ctrlLabels();
              } else {
                let ctrl = false;
                Object.entries(options).forEach((elm) => {
                  if (
                    elm[1].text == this.end_customer.company_name.company_name
                  ) {
                    document.getElementsByClassName("endConsumer")[0].value =
                      elm[1]._value;
                    ctrl = true;
                    this.updateInfo = true;
                    this.end_customer.id = elm[1]._value;
                  }
                });
                if (!ctrl) {
                  // non è un contatto salvato
                  this.viewNewCustomerEnd = true;
                  this.end_customer.company_name_new =
                    this.end_customer.company_name.company_name;
                  document.getElementsByClassName("endConsumer")[0].value =
                    "9999";
                  this.ctrlLabels();
                }
              }
            }
          }, 1000);
        }
        if (recoveryValue.form.client_po != null) {
          this.client_po = recoveryValue.form.client_po;
        }

        this.ctrlLabels();
      }
    },
    saveCompanyName() {
      this.end_customer.company_name = this.end_customer.company_name_new;
    },
  },
  activated() {
    this.getEndCustomers();
  },
  mounted() {},
};
</script>
