// stores/datacenter.js
import { defineStore } from "pinia";
import axios from "axios";
import { useUserStore } from "@/stores/UserStore";
import { MyCrypto } from "@/services/MyCrypto";

// Define the cleanObject function outside of the store definition
export function cleanObject(obj) {
  const result = {};
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === "object") {
      const cleanedNestedObject = cleanObject(obj[key]);
      if (Object.keys(cleanedNestedObject).length !== 0) {
        result[key] = cleanedNestedObject;
      }
    } else if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== "" &&
      obj[key] !== 0
    ) {
      result[key] = obj[key];
    }
  }
  return result;
}

const url = MyCrypto(window.VUE_APP_MISTRA_URL, 1);

export const useDatacenterStore = defineStore("datacenter", {
  state: () => ({
    colocation: {},
    isFromvalidation: false,
    redundantData: {},
    isFromvalidationIntra: false,
    noRequired: false,
    isCartItem: false,
    boxData: {},
    cartItem: {},
    wrongFileFormat: false,
    wrongFileFormatR: false,
    buildings: [],
    pageNumber: 1,
    kindOfkit: "",
    is_my_muffolaR: false,
    wings: [],
    roomAName: "",
    roomBName: "",
    rackAName: "",
    rackBName: "",
    rackANameR: "",
    roomANameR: "",
    roomBNameR: "",
    rackBNameR: "",
    building: "",
    rBuilding: "",
    rWing: "",
    wing: "",
    wingName: "",
    buildingName: "",
    rWingName: "",
    rBuildingName: "",
    islandName: "",
    islandNameR: "",
    info: null,
    xConInfo: [],
    kitInfo: [],
    kitRelatedProduct: {},
    infraRequiredFields: [],
    rooms: [],
    islands: {},
    racksA: [],
    rackUnitsA: {},
    patchPanelsA: {},
    racksB: [],
    racksBR: [],
    racksAR: [],
    patchPanelsB: {},
    rPatchPanelsA: {},
    rRackUnitsB: {},
    rPatchPanelsB: {},
    target_datacenter: "",
    fileInput: null,
    starter_subscription_time: null,
    dc_address: "",
    nameXC: "",
    datacenter: "",
    infracampus: {
      isInfra: false,
      isRack: false,
    },
    supportTicket: "",
    aend: {
      cage: "",
      rack: "",
      patch_panel_to_mmr: "",
      rack_unit: "",
      device_port: "",
    },
    bend: {
      is_my_rack: false,
      cage: "",
      island: {},
      rack: "",
      device_port: "",
      is_my_rack_red: false,
    },
    kitLength: 88,
    raend: {
      cage: "",
      rack: "",
      patch_panel_to_mmr: "",
      rack_unit: "",
      device_port: "",
    },
    rbend: {
      is_my_rack: false,
      cage: "",
      island: "",
      rack: "",
      device_port: "",
    },
    is_my_rack_red: false,
    redundancy: false,
    ref_name: "",
    ref_surname: "",
    ref_phone: "",
    ref_email: "",
    purchase_order: "",
    description: "",
    xc_article: "",
    mrc: 0,
    nrc: 0,
    rmrc: 0,
    rnrc: 0,
    attachment_rbend: {},
    attachment_b_end: {},
    master_id: 0,
    redundancy_ids: [],
    fieldView: {
      input: false,
      attach: false,
    },
    islandTouched: false,
    isCompleted: false,
    loaName: "",
    is_my_muffola: false,
    redunandLoaName: "",
    crossEndBValidation: {
      roomIsValid: false,
      isletIsValid: false,
      rackIsValid: false,
      fileIsValid: false,
      wingIsValid: false,
      buildingIsValid: false,
    },
    isValidationCheckInitiated: false,
  }),
  getters: {
    // Local - Linea principale
    localStep2IsValid() {
      let aEndIsValid,
        bEndIsValid = false;

      aEndIsValid = this.aend.cage !== "" && this.aend.rack !== "";

      // must have LOA or cage + rack
      bEndIsValid = this.bend.is_my_rack
        ? this.bend.cage !== "" && this.bend.rack !== ""
        : Object.keys(this.attachment_b_end).length > 0;

      // validation
      return aEndIsValid && bEndIsValid;
    },

    // Infra - Linea principale
    infraStep2IsValid() {
      const LOA_REQUIRED = "loa";
      const MUFFOLA_REQUIRED = "is_my_muffola";

      let aEndIsValid = this.aend.cage !== "" && this.aend.rack !== "",
        bEndIsValid = false;

      /**
       * from JSON schema in: cross_connect_details -> b_end -> required
       * possible options passed from tipology choice:
       *  - ['loa']
       *  - ['is_my_muffola']
       *  - []
       */

      // no fields required (empty array)
      if (!this.infraRequiredFields.length) {
        return aEndIsValid;
      }
      // console.log("this.infraRequiredFields", this.infraRequiredFields);
      // loa is required
      if (this.infraRequiredFields[0] === LOA_REQUIRED) {
        return aEndIsValid && Object.keys(this.attachment_b_end).length > 0;
      }

      // muffola is required
      if (this.infraRequiredFields[0] === MUFFOLA_REQUIRED) {
        bEndIsValid = this.is_my_muffola
          ? this.building !== "" && this.wing !== ""
          : Object.keys(this.attachment_b_end).length > 0;
      }

      // validation
      return aEndIsValid && bEndIsValid;
    },

    // Infra - Linea ridondata
    infraStep3IsValid() {
      if (!this.redundancy) {
        return true;
      }
      const LOA_REQUIRED = "loa";
      const MUFFOLA_REQUIRED = "is_my_muffola";

      let aEndIsValid = this.raend.cage !== "" && this.raend.rack !== "",
        bEndIsValid = false;

      /**
       * from JSON schema in: cross_connect_details -> b_end -> required
       * possible options passed from tipology choice:
       *  - ['loa']
       *  - ['is_my_muffola']
       *  - []
       */
      // no fields required (empty array)
      if (!this.infraRequiredFields?.length) {
        return aEndIsValid;
      }

      // loa is required
      if (this.infraRequiredFields[0] === LOA_REQUIRED) {
        return aEndIsValid && Object.keys(this.attachment_rbend).length > 0;
      }

      // muffola is required
      if (this.infraRequiredFields[0] === MUFFOLA_REQUIRED) {
        bEndIsValid = this.is_my_muffolaR
          ? this.rBuilding !== "" && this.rWing !== "" // they come as unidentified
          : Object.keys(this.attachment_rbend).length > 0; // this comes as an object with id and name unidentified
      }

      // validation
      return aEndIsValid && bEndIsValid;
    },
    // Local - Linea ridondata
    localStep3IsValid() {
      if (!this.redundancy) {
        return true;
      }
      let aEndIsValid,
        bEndIsValid = false;
      if (!this.redundancy) {
        return true;
      }
      aEndIsValid = this.raend.cage !== "" && this.raend.rack !== "";

      // must have LOA or cage + rack
      bEndIsValid = this.rbend.is_my_rack
        ? this.rbend.cage !== "" && this.rbend.rack !== ""
        : Object.keys(this.attachment_rbend).length > 0;

      // validation
      return aEndIsValid && bEndIsValid;
    },
  },
  actions: {
    async mapCartItemToDatacenterState(cartItem, redunantData) {
      this.roomAName = cartItem.cross_connect_details.a_end.room;
      this.roomBName = cartItem.cross_connect_details.b_end.room;
      this.rackAName = cartItem.cross_connect_details.a_end.rack;
      this.rackBName = cartItem.cross_connect_details.b_end.rack;
      this.islandName = cartItem.cross_connect_details.b_end.islet;
      if (cartItem?.cross_connect_details?.b_end.loa)
        this.attachment_b_end = {
          id: cartItem?.cross_connect_details?.b_end?.loa,
          name: cartItem?.cross_connect_details?.b_end?.loa_name,
        };
      else {
        this.attachment_b_end = {};
      }
      cartItem?.cross_connect_details?.b_end?.loa_name || "";
      this.kitRelatedProduct.title = cartItem.related_products[0].group_name;
      this.kitRelatedProduct.id = cartItem.kit_id;
      this.kitRelatedProduct.related_products = cartItem.related_products;
      this.description = "";
      this.purchase_order = ""; // Assuming it can be set to a string for the placeholder
      this.datacenter = cartItem.cross_connect_details.datacenter_id;
      this.redundancy = cartItem.cross_connect_details.is_redundant; // Assuming a boolean value, set to false as a placeholder
      this.aend.rack = cartItem.cross_connect_details.a_end.rack_id; // Assuming it's a number, set to 0 as a placeholder
      this.aend.device_port =
        cartItem.cross_connect_details.a_end.device_and_port;
      this.aend.cage = cartItem.cross_connect_details.a_end.room_id;
      this.aend.patch_panel_to_mmr =
        cartItem.cross_connect_details.a_end.patch_panel_to_mmr;
      this.bend.is_my_rack =
        cartItem.cross_connect_details.b_end.loa || this.noRequired
          ? false
          : true;

      this.bend.is_my_rack_red = cartItem.cross_connect_details.b_end.loa
        ? false
        : true;
      this.bend.rack = cartItem.cross_connect_details.b_end.rack_id;
      this.bend.cage = cartItem.cross_connect_details.b_end.room_id;
      this.bend.island = cartItem.cross_connect_details.b_end.islet_id;
      this.bend.device_port =
        cartItem.cross_connect_details.b_end.device_and_port;
      this.roomBName = cartItem.cross_connect_details.b_end.room;
      this.rackBName = cartItem.cross_connect_details.b_end.rack_name;
      this.building = cartItem.cross_connect_details.b_end.building;
      this.wing = cartItem.cross_connect_details.b_end.wing;
      this.wingName = cartItem.cross_connect_details.b_end.wing_name;
      this.buildingName = cartItem.cross_connect_details.b_end.building_name;
      //redunant data
      if (redunantData) {
        this.roomANameR = redunantData?.cross_connect_details.a_end.room;
        this.roomBNameR = redunantData?.cross_connect_details.b_end.room;
        this.islandNameR = redunantData?.cross_connect_details.b_end.islet;
        this.is_my_muffolaR =
          redunantData?.cross_connect_details.b_end.is_my_muffola;
        this.rackANameR = redunantData?.cross_connect_details.a_end.rack;
        this.rackBNameR = redunantData?.cross_connect_details.b_end.rack;
        if (redunantData?.cross_connect_details?.b_end?.loa)
          this.attachment_rbend = {
            id: redunantData?.cross_connect_details?.b_end?.loa,
            name: redunantData?.cross_connect_details?.b_end?.loa_name,
          };
        else {
          this.attachment_rbend = {};
        }

        this.master_id = redunantData?.cross_connect_details.master_id;
        this.redundancy_ids = cartItem.cross_connect_details.redundancy_ids;
        this.raend.rack = redunantData?.cross_connect_details.a_end.rack_id; // Assuming it's a number, set to 0 as a placeholder
        this.raend.device_port =
          redunantData?.cross_connect_details.a_end.device_and_port;
        this.raend.cage = redunantData?.cross_connect_details.a_end.room_id;
        this.raend.patch_panel_to_mmr =
          redunantData?.cross_connect_details.a_end.patch_panel_to_mmr;
        this.rbend.is_my_rack =
          this.kindOfkit === "is_my_muffola"
            ? this.is_my_muffolaR
            : !redunantData
            ? false
            : !(
                redunantData?.cross_connect_details.b_end.loa || this.noRequired
              );
        this.rbend.is_my_rack_red =
          !redunantData?.cross_connect_details.b_end.loa;

        this.rbend.rack = redunantData?.cross_connect_details.b_end.rack_id;
        this.rbend.cage = redunantData?.cross_connect_details.b_end.room_id;
        this.rbend.island = redunantData?.cross_connect_details.b_end.islet_id;
        this.rbend.device_port =
          redunantData?.cross_connect_details.b_end.device_and_port;

        this.rBuilding = redunantData?.cross_connect_details.b_end.building;
        this.rBuildingName =
          redunantData?.cross_connect_details.b_end.building_name;

        this.rWing = redunantData?.cross_connect_details.b_end.wing;
        this.rWingName = redunantData?.cross_connect_details.b_end.wing_name;
      }
      // common
      this.loaName = cartItem.cross_connect_details.b_end.loa_name;
      this.redunandLoaName = redunantData?.cross_connect_details?.b_end.loa;
      this.ref_name = cartItem.cross_connect_details.contact.first_name;
      this.ref_surname = cartItem.cross_connect_details.contact.last_name;
      this.ref_phone = cartItem.cross_connect_details.contact.phone;
      this.ref_email = cartItem.cross_connect_details.contact.email;
      this.is_my_muffola = cartItem.cross_connect_details.b_end.is_my_muffola;
      this.mrc = cartItem.related_products[0].products[0]?.price.mrc;
      this.nrc = cartItem.related_products[0].products[0]?.price.nrc;
      this.boxData.name = cartItem.cross_connect_details.datacenter_name;
      this.boxData.address = cartItem.cross_connect_details.datacenter_address;
      this.xc_article = cartItem.related_products[0].products[0]?.title;
      this.dc_address = cartItem.cross_connect_details.datacenter_address;
      this.purchase_order = cartItem.client_po;
      this.description = cartItem.notes;

      // Handle any other fields necessary for your application logic
    },

    async updateCartItem(itemId) {
      // console.log("updating cartItem");
      const authStore = useUserStore();
      // Construct the payload using your store's or component's state data
      const payload = {
        kit_id: this.kitRelatedProduct.id.toString(),
        related_products: [
          {
            group_name: await this.kitRelatedProduct.related_products[0]
              .group_name,
            id: await this.kitRelatedProduct.related_products[0].products[0].id,
            quantity: 1, // or any other logic to determine quantity
          },
        ],
        notes: this.description ?? "",
        client_po: this.purchase_order?.toString(),
        cross_connect_details: {
          datacenter_id: await this.datacenter,
          is_redundant: await this.redundancy,
          redundancy_ids: await this.redundancy_ids,
          a_end: {
            rack_id: Number(this.aend.rack),
            device_and_port: await this.aend.device_port,
            patch_panel_to_mmr: await this.aend.patch_panel_to_mmr,
          },
          b_end: {
            is_my_rack: await this.bend.is_my_rack,
            rack_id: Number(this.bend.rack),
            device_and_port: await this.bend.device_port,
            building: await this.building,
            wing: await this.wing,
            loa: await this.attachment_b_end?.id,
          },
          contact: {
            first_name: this.ref_name,
            last_name: this.ref_surname,
            phone: this.ref_phone,
            email: this.ref_email,
          },
        },
      };
      if (this.kindOfkit) {
        payload.cross_connect_details.b_end.is_my_muffola = this.is_my_muffola;
      }
      if (payload.cross_connect_details.b_end.is_my_rack === true) {
        if (this.is_my_muffola === true && this.kindOfkit === "is_my_muffola") {
          // payload.cross_connect_details.b_end.loa = "";
        }
      }

      // Clean the payload before sending
      const cleanedPayload = cleanObject(payload);
      try {
        const response = await axios.put(
          `${url}sales/v1/cart-item-001/${itemId}`, // Construct the URL with itemId
          cleanedPayload,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        console.log("Update response:", response);
        // Handle the response as needed, maybe updating local state or UI accordingly
      } catch (error) {
        console.error("Error updating cart item:", error);
        throw error; // Rethrow the error to propagate it to the caller
      }
    },
    async updateCartItemR(itemId, masterId) {
      // console.log("updating redundant cartItem");
      const authStore = useUserStore();
      // Construct the payload using your store's or component's state data
      const payload = {
        kit_id: this.kitRelatedProduct.id.toString(),
        related_products: [
          {
            group_name: await this.kitRelatedProduct.related_products[0]
              .group_name,
            id: await this.kitRelatedProduct.related_products[0].products[0].id,
            quantity: 1, // or any other logic to determine quantity
          },
        ],
        notes: this.description,
        client_po: this.purchase_order?.toString(),
        cross_connect_details: {
          datacenter_id: await this.datacenter,
          is_redundant: await this.redundancy,
          master_id: masterId,
          a_end: {
            rack_id: Number(this.raend.rack),
            device_and_port: await this.raend.device_port,
            patch_panel_to_mmr: await this.raend.patch_panel_to_mmr,
          },
          b_end: {
            is_my_rack: await this.rbend.is_my_rack,
            rack_id: Number(this.rbend.rack),
            device_and_port: await this.rbend.device_port,
            building: await this.rBuilding,
            wing: await this.rWing,
            loa: await this.attachment_rbend?.id,
          },
          contact: {
            first_name: this.ref_name,
            last_name: this.ref_surname,
            phone: this.ref_phone,
            email: this.ref_email,
          },
        },
      };
      if (this.kindOfkit) {
        payload.cross_connect_details.b_end.is_my_muffola = this.is_my_muffolaR;
      }
      if (payload.cross_connect_details.b_end.is_my_rack === true) {
        if (
          this.is_my_muffolaR === true &&
          this.kindOfkit === "is_my_muffola"
        ) {
          // payload.cross_connect_details.b_end.loa = "";
        }
      }

      // Clean the payload before sending
      const cleanedPayload = cleanObject(payload);

      try {
        const response = await axios.put(
          `${url}sales/v1/cart-item-001/${itemId}`, // Construct the URL with itemId
          cleanedPayload,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        console.log("Update response:", response);
        // Handle the response as needed, maybe updating local state or UI accordingly
      } catch (error) {
        console.error("Error updating redundant cart item:", error);
        throw error; // Rethrow the error to propagate it to the caller
      }
    },

    async postCartItem() {
      const authStore = useUserStore();
      // Construct the payload using your store's state data
      const payload = {
        // Use actual values from your store's state
        kit_id: await this.kitRelatedProduct.id.toString(),
        related_products: [
          {
            group_name: await this.kitRelatedProduct.related_products[0]
              .group_name,
            id: await this.kitRelatedProduct.related_products[0].products[0].id,
            quantity: 1, // or any other logic to determine quantity
          },
        ],

        notes: await this.description,
        client_po: await this.purchase_order?.toString(),
        cross_connect_details: {
          datacenter_id: await this.datacenter,
          is_redundant: await this.redundancy,
          a_end: {
            rack_id: Number(this.aend.rack),
            device_and_port: await this.aend.device_port,
            patch_panel_to_mmr: await this.aend.patch_panel_to_mmr,
          },
          b_end: {
            is_my_rack: await this.bend.is_my_rack,
            device_and_port: await this.bend.device_port,
            building: await this.building,
            wing: await this.wing,
            loa: await this.attachment_b_end?.id,
          },
          contact: {
            first_name: this.ref_name,
            last_name: this.ref_surname,
            phone: this.ref_phone,
            email: this.ref_email,
          },
        },
      };
      if (this.kindOfkit) {
        payload.cross_connect_details.b_end.is_my_muffola = this.is_my_muffola;
      }
      if (!this.loaName) {
        payload.cross_connect_details.b_end.rack_id = Number(this.bend.rack);
      }
      if (
        payload.cross_connect_details.is_redundant &&
        !this.aend.patch_panel_to_mmr
      ) {
        payload.cross_connect_details.a_end.patch_panel_to_mmr = "MMA";
      }
      if (payload.cross_connect_details.b_end.is_my_rack == true)
        payload.cross_connect_details.b_end.loa = "";
      // console.log(payload);
      // Clean the payload before sending
      const cleanedPayload = cleanObject(payload);
      // console.log(cleanedPayload);

      try {
        const response = await axios.post(
          url + "sales/v1/cart-item-001",
          cleanedPayload,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        // console.log("Post response:", response);
        this.master_id = response.data;
        // Handle the response as needed
      } catch (error) {
        console.error("Error posting cart item:", error);
        throw error; // Rethrow the error to propagate it to the caller
      }
    },
    async postSupportTicket(desc) {
      const authStore = useUserStore();
      // Construct the payload using your store's state data
      const payload = {
        description: desc,
        source: "CrossConnectsSupport",
      };
      try {
        const response = await axios.post(url + "tickets/v1/generic", payload, {
          headers: { Authorization: `Bearer ${authStore.token}` },
        });
        this.supportTicket = await response.data;
        // Handle the response as needed
      } catch (error) {
        console.error("Error posting cart item:", error);
        throw error; // Rethrow the error to propagate it to the caller
      }
    },

    async postCartItemR(masterId) {
      const authStore = useUserStore();
      // Construct the payload using your store's state data
      const payload = {
        // Use actual values from your store's state
        kit_id: await this.kitRelatedProduct.id.toString(),
        related_products: [
          {
            group_name: await this.kitRelatedProduct.related_products[0]
              .group_name,
            id: await this.kitRelatedProduct.related_products[0].products[0].id,
            quantity: 1, // or any other logic to determine quantity
          },
        ],
        notes: await this.description,
        client_po: await this.purchase_order?.toString(),
        network_access_details: {},
        cross_connect_details: {
          datacenter_id: await this.datacenter,
          master_id: masterId,
          is_redundant: await this.redundancy,
          a_end: {
            rack_id: Number(this.raend.rack),
            device_and_port: await this.raend.device_port,
            patch_panel_to_mmr: await this.raend.patch_panel_to_mmr,
          },
          b_end: {
            is_my_rack: await this.rbend.is_my_rack,
            device_and_port: await this.rbend.device_port,
            building: await this.rBuilding,
            wing: await this.rWing,
            loa: await this.attachment_rbend?.id,
          },
          contact: {
            first_name: this.ref_name,
            last_name: this.ref_surname,
            phone: this.ref_phone,
            email: this.ref_email,
          },
        },
      };
      if (this.kindOfkit) {
        payload.cross_connect_details.b_end.is_my_muffola = this.is_my_muffolaR;
      }
      if (
        !this.redunandLoaName &&
        this.rbend.is_my_rack &&
        this.kindOfkit !== "is_my_muffola"
      ) {
        payload.cross_connect_details.b_end.rack_id = Number(this.rbend.rack);
      }
      if (
        !payload.cross_connect_details.a_end.patch_panel_to_mmr &&
        this.aend.patch_panel_to_mmr === ""
      ) {
        payload.cross_connect_details.a_end.patch_panel_to_mmr = "MMB";
      }
      if (
        (payload.cross_connect_details.b_end.is_my_rack == true &&
          this.kindOfkit !== "loa") ||
        !this.redunandLoaName
      )
        payload.cross_connect_details.b_end.loa = "";
      const cleanedPayload = cleanObject(payload);
      cleanedPayload.cross_connect_details.a_end.rack_id = Number(
        cleanedPayload.cross_connect_details.a_end.rack_id
      );
      if (cleanedPayload.cross_connect_details.b_end.rack_id) {
        cleanedPayload.cross_connect_details.b_end.rack_id = Number(
          cleanedPayload.cross_connect_details.b_end.rack_id
        );
      }

      try {
        // console.log("cleanedPayload", cleanedPayload);
        const response = await axios.post(
          url + "sales/v1/cart-item-001",
          cleanedPayload,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        console.log("Post response:", response.data);
        // Handle the response as needed
      } catch (error) {
        console.error("Error posting cart item:", error);
        throw error; // Rethrow the error to propagate it to the caller
      }
    },

    async fetchDatacenterInfo() {
      const authStore = useUserStore();
      try {
        const response = await axios.get(url + "assets/v1/datacenter", {
          headers: { Authorization: `Bearer ${authStore.token}` },
        });
        this.info = response.data;
      } catch (error) {
        console.error("Error fetching datacenter info:", error);
      }
    },
    async fetchBuildings() {
      const authStore = useUserStore();
      try {
        const response = await axios.get(url + "front/v1/xcbuilds", {
          headers: { Authorization: `Bearer ${authStore.token}` },
        });
        this.buildings = response.data;
      } catch (error) {
        console.error("Error fetching kit info:", error);
      }
    },
    async fetchWings() {
      const authStore = useUserStore();
      try {
        const response = await axios.get(url + "front/v1/xcwings", {
          headers: { Authorization: `Bearer ${authStore.token}` },
        });
        this.wings = response.data;
      } catch (error) {
        console.error("Error fetching kit info:", error);
      }
    },
    async fetchRooms(datacenterId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/dc-rooms/${datacenterId}`,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        // Using datacenterId as a key to store rooms specific to the datacenter
        this.rooms[datacenterId] = response.data;
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    },
    async fetchKitsXconn(datacenterId, isLocal) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url +
            `sales/v1/xconn-kits?is_local=${isLocal}&datacenter_id=${datacenterId}`,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        // Using datacenterId as a key to store rooms specific to the datacenter
        this.xConInfo = response.data;
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    },
    async fetchRacksA(roomId) {
      const authStore = useUserStore();
      try {
        const response = axios.get(url + `assets/v1/racks/${roomId}`, {
          headers: { Authorization: `Bearer ${authStore.token}` },
        });
        return await response;
      } catch (error) {
        console.error("Error fetching racks:", error);
      }
    },
    async fetchRacksB(roomId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/racks/${roomId}`,

          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        return await response;
      } catch (error) {
        console.error("Error fetching racks:", error);
      }
    },
    async fetchRacksForRedundancy(roomId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/racks/${roomId}`,

          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        this.racksBR[roomId] = response.data;
      } catch (error) {
        console.error("Error fetching racks:", error);
      }
    },
    async fetchRacksACartMode(roomId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(url + `assets/v1/racks/${roomId}`, {
          headers: { Authorization: `Bearer ${authStore.token}` },
        });
        this.racksAR[roomId] = response.data;
      } catch (error) {
        console.error("Error fetching racks:", error);
      }
    },
    async fetchRacksBCartMode(roomId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/racks/${roomId}`,

          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        this.racksBR[roomId] = response.data;
      } catch (error) {
        console.error("Error fetching racks:", error);
      }
    },
    async fetchRacksBByIsland(islandId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/racks-by-islet?islet_id=${islandId}`,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        return await response;
      } catch (error) {
        console.error("Error fetching racks:", error);
      }
    },
    async fetchRacksBByIslandForRedundancy(islandId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/racks-by-islet?islet_id=${islandId}`,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        this.racksBR[islandId] = response.data;
      } catch (error) {
        console.error("Error fetching racks:", error);
      }
    },
    async fetchRackUnitsA(rackId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/rack-unit/${rackId}`,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        this.rackUnitsA[rackId] = response.data;
      } catch (error) {
        console.error("Error fetching rack units:", error);
      }
    },
    async fetchRackUnitsB(rackId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/rack-unit/${rackId}`,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        this.rackUnitsB[rackId] = response.data;
      } catch (error) {
        console.error("Error fetching rack units:", error);
      }
    },
    async fetchIslands(roomId) {
      const authStore = useUserStore();
      try {
        const response = await axios.get(
          url + `assets/v1/islets?room_id=${roomId}`,
          {
            headers: { Authorization: `Bearer ${authStore.token}` },
          }
        );
        return await response;
      } catch (error) {
        console.error("Error fetching islands:", error);
      }
    },
    // handle file setting
    async handleFileChange(event, attachmentKey, isRedundancy) {
      // console.log("handleFileChange:", event);
      // console.log("FILES:", files);
      const files = event.target.files;
      if (files.length > 0) {
        const file = files[0];
        const fileId = await this.fetchFiles(file, isRedundancy);
        this[attachmentKey] = { id: fileId, name: files[0].name };
        if (fileId < 0) {
          // upload failed, return immediately
          return;
        }
        return { id: fileId, name: files[0].name };
      }
    },

    async fetchFiles(file, isRedundant) {
      const authStore = useUserStore();

      try {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("type", "loa");

        const response = await axios.post(url + `files/v1/upload`, formData, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("file", response);

        if (!isRedundant) {
          this.loaName = response.data;
          // console.log("file redundant", response);
          this.wrongFileFormat = false;
        } else {
          this.redunandLoaName = response.data;
          // console.log("file no redundant", this.redunandLoaName);
          this.wrongFileFormatR = false;
        }

        return response.data;
      } catch (error) {
        if (error.response.status === 400) {
          if (!isRedundant) {
            this.wrongFileFormat = true;
          } else {
            this.wrongFileFormatR = true;
          }
        }
        return -1;
      }
    },

    async clearData() {
      // Reset each property to its initial value
      this.info = null;
      this.rooms = [];
      this.is_my_muffola = false;
      this.is_my_muffolaR = false;
      this.racksA = [];
      this.rackUnitsA = {};
      this.patchPanelsA = {};
      this.islands = {};
      this.racksB = [];
      this.racksAR = [];
      this.racksBR = [];
      this.rackUnitsB = {};
      this.target_datacenter = "";
      this.dc_address = "";
      this.loaName = null;
      this.redunandLoaName = null;
      this.starter_subscription_time = null;
      this.datacenter = "";
      this.infracampus = { isInfra: false, isRack: false };
      this.aend = {
        cage: "",
        rack: "",
        patch_panel_to_mmr: "",
        rack_unit: "",
        device_port: "",
      };

      this.crossEndBValidation = {
        roomIsValid: false,
        isletIsValid: false,
        rackIsValid: false,
        fileIsValid: false,
        wingIsValid: false,
        buildingIsValid: false,
      };
      this.bend = {
        cage: "",
        island: "",
        rack: "",
        device_port: "",
        is_my_rack: false,
      };
      this.raend = {
        cage: "",
        rack: "",
        patch_panel_to_mmr: "",
        rack_unit: "",
        device_port: "",
      };
      this.rbend = {
        cage: "",
        island: "",
        rack: "",
        device_port: "",
        is_my_rack: false,
      };
      this.redundancy = false;
      this.ref_name = "";
      this.ref_surname = "";
      this.ref_phone = "";
      this.ref_email = "";
      this.purchase_order = "";
      this.description = "";
      this.xc_article = "";
      this.noRequired = false;
      this.mrc = 0;
      this.nrc = 0;
      this.rmrc = 0;
      this.rnrc = 0;
      this.kindOfkit = "";
      this.is_my_muffolaR = false;
      this.attachment_rbend = {};
      this.attachment_b_end = {};
      this.isCompleted = false;
      this.kitLength = 88;
      this.loa = "";
      this.kitRelatedProduct = {};
      this.wing = "";
      this.building = "";
      this.rWing = "";
      this.rBuilding = "";
      this.wingName = "";
      this.buildingName = "";
      this.rWingName = "";
      this.rBuildingName = "";
      this.isFromvalidation = false;
      this.isFromvalidationIntra = false;
      this.cartItem = {};
      this.colocation = {};
      this.isCartItem = false;
      this.is_my_rack_red = false;
      this.wrongFileFormat = false;
      this.wrongFileFormatR = false;
      this.roomBNameR = "";
      this.rackBNameR = "";
      this.islandNameR = "";
      this.roomANameR = "";
      this.rackANameR = "";
      this.roomAName = "";
      this.rackAName = "";
      this.roomBName = "";
      this.rackBName = "";
      this.islandName = "";
      this.master_id = 0;
    },
  },
});
