<template>
  <div id="OrderNetworkCoverageSummary">
    <div class="contentSummary">
      <div class="padding60">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <div id="MyHeaderPage">
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--8-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                  <h1>{{ $t("detailOrder.title") }}</h1>
                  <hr>
                </div>
                <div class="mdl-cell mdl-cell--4-col mdl-cell--12-col-tablet mdl-cell--12-col-phone" v-if="canEdit">
                  <p style="text-align: justify;">{{ $t("detailOrder.infoBtn") }}</p>
                  <div class="contentButton btnDetailOrder">
                    <button id="submitCustomerEnd" class="cdlButtonGreen" @click="submit()">
                      {{ $t('detailOrder.sendToCdlan') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loader">
          <div class="infoKit" :class="typeSolution == 1 ? 'pink' : 'green'" style="margin-top: 3rem;">
            <div class="mdl-grid">
              <div class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                <h3>{{ $t("detailOrder.boxTitle") }}</h3>
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.state") }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ $t("detailOrder.states." + data.state) }}</span><br></div>
                </div>
                <hr>
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.nOrder") }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ data.order_number }}</span><br></div>
                </div>
                <hr>
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.dateRequest") }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ getDate(data.submission_date) }}</span><br></div>
                </div>
                <hr>
                <div>
                  <div class="row">
                    <div class="contentImage"><span class="title">{{ $t("detailOrder.dateAttivation") }}</span></div>
                    <div class="alignCenter"><span class="detailThree">-</span><br></div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="contentImage"><span class="title">{{ $t("detailOrder.clientPo") }}</span></div>
                    <div class="alignCenter"><span class="detailThree">{{ data.client_po?? '-' }}</span><br></div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="contentImage"><span class="title">{{ $t("detailOrder.articleCode") }}</span></div>
                    <div class="alignCenter"><span class="detailThree">{{ data.bundle_code?? '-' }}</span><br></div>
                  </div>
                </div>
                <hr v-if="IsMobile">
              </div>
              <div class="mdl-cell mdl-cell--2-col" v-if="!IsMobile"></div>

              <div class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone marginDesktop">
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.serviceDuration") }} {{ IsMobile }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ data.starter_subscription_months }}</span><br></div>
                </div>
                <hr>
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.renewals") }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ data.regular_subscription_months }}</span><br></div>
                </div>
                <hr>
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.activationDys") }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ data.activation_days }}</span><br></div>
                </div>
                <hr>
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.billingPeriod") }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ data.billing_period?? '-' }}</span><br></div>
                </div>
                <hr>
                <h4>{{ $t("detailOrder.titlePrice") }}</h4>
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.priceNrc") }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ getPrice(data.total_price?.nrc) }}€</span><br></div>
                </div>
                <br />
                <div class="row">
                  <div class="contentImage"><span class="title">{{ $t("detailOrder.priceMrc") }}</span></div>
                  <div class="alignCenter"><span class="detailThree">{{ getPrice(data.total_price?.mrc) }}€</span><br></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone" style="width: auto;">
              <h2 class="propsMobile">{{ $t("detailOrder.rowsOrder") }}</h2>
            </div>
          </div>
          <div v-for="(row, key) in data.rows" :key="key" class="mdl-grid contentRelatedServices">
            <div class="mdl-cell mdl-cell--12-col">
              <h3>{{ row.title }}</h3>
              <hr>
            </div>
            <div class="mdl-cell mdl-cell--4-col title">{{ $t("detailOrder.serialnumber") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="IsMobile">{{ row.serial_number }}<br /><br /></div>

            <div class="mdl-cell mdl-cell--4-col title">{{ $t("detailOrder.description") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="IsMobile">{{ row.description }}<br /><br /></div>
            
            <div class="mdl-cell mdl-cell--4-col title">{{ $t("detailOrder.state") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="IsMobile">{{ row.state }}<br /><br /></div>

            <div class="mdl-cell mdl-cell--4-col value" v-if="!IsMobile">{{ row.serial_number }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="!IsMobile">{{ row.description }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="!IsMobile">{{ row.state }}</div>

            <div class="mdl-cell mdl-cell--12-col"></div>

            <div class="mdl-cell mdl-cell--4-col title" v-if="row.category_specific_fields?.address">{{ $t("detailOrder.address") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="row.category_specific_fields?.address && IsMobile">{{ row.category_specific_fields.address.address + ' ' + row.category_specific_fields.address.number+ ' ' + row.category_specific_fields.address.city+ ' ' + row.category_specific_fields.address.province }}<br /><br /></div>
            
            <div class="mdl-cell mdl-cell--4-col title" v-if="row.category_specific_fields?.commercial_profile">{{ $t("detailOrder.speedDownload") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="row.category_specific_fields?.commercial_profile && IsMobile">{{ getSize(row.category_specific_fields.commercial_profile.downstream_bandwidth) }}<br /><br /></div>

            <div class="mdl-cell mdl-cell--4-col title" v-if="row.category_specific_fields?.commercial_profile">{{ $t("detailOrder.speedUpload") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="row.category_specific_fields?.commercial_profile && IsMobile">{{ getSize(row.category_specific_fields.commercial_profile.upstream_bandwidth) }}<br /><br /></div>

            <div class="mdl-cell mdl-cell--4-col value" v-if="row.category_specific_fields?.address && !IsMobile">{{ row.category_specific_fields.address.address + ' ' + row.category_specific_fields.address.number+ ' ' + row.category_specific_fields.address.city+ ' ' + row.category_specific_fields.address.province }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="row.category_specific_fields?.commercial_profile && !IsMobile">{{ getSize(row.category_specific_fields.commercial_profile.downstream_bandwidth) }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="row.category_specific_fields?.commercial_profile && !IsMobile">{{ getSize(row.category_specific_fields.commercial_profile.upstream_bandwidth) }}</div>

            <div class="mdl-cell mdl-cell--12-col"></div>

            <div class="mdl-cell mdl-cell--12-col">
              <h4>{{ $t("detailOrder.priceRow") }}</h4>
            </div>
            <div class="mdl-cell mdl-cell--4-col title">{{ $t("detailOrder.priceNrc") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="IsMobile">{{ getPrice(row.unit_price.nrc) }}€<br /><br /></div>

            <div class="mdl-cell mdl-cell--4-col title">{{ $t("detailOrder.rowPriceMrc") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="IsMobile">{{ getPrice(row.unit_price.nrc) }}€<br /><br /></div>

            <div class="mdl-cell mdl-cell--4-col title">{{ $t("detailOrder.quantity") }}</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="IsMobile">{{ row.selected_qty }}<br /><br /></div>

            <div class="mdl-cell mdl-cell--4-col value" v-if="!IsMobile">{{ getPrice(row.unit_price.nrc) }}€</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="!IsMobile">{{ getPrice(row.unit_price.mrc) }}€</div>
            <div class="mdl-cell mdl-cell--4-col value" v-if="!IsMobile">{{ row.selected_qty }}</div>

            <div class="mdl-cell mdl-cell--12-col" v-if="row.category_specific_fields">
              <!-- BLOCCO -->
              <div class="mdl-cell mdl-cell--12-col contentRef">

                <!-- XC -->
                <div class="summaryXC" v-if="typeSolution == 2">
                  <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--5-col boxXC mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                      <div class="mdl-grid">
                        <div class="mdl-cell mdl-cell--12-col">
                          <h3>A-END</h3>
                          <hr />
                        </div>
                        <div class="mdl-cell mdl-cell--6-col title">{{ $t("crossConnects.place") }}</div>
                        <div class="mdl-cell mdl-cell--6-col value">{{ row.category_specific_fields.a_end?.room?? '-' }}</div>
                        <div class="mdl-cell mdl-cell--6-col title">Rack</div>
                        <div class="mdl-cell mdl-cell--6-col value">{{ row.category_specific_fields.a_end?.rack?? '-' }}</div>
                        <div class="mdl-cell mdl-cell--6-col title">{{ $t("crossConnects.deviceAndPort") }}</div>
                        <div class="mdl-cell mdl-cell--6-col">
                          <div>
                            <input
                              style="width: 95%;"
                              class="inputOrder"
                              :class="{readonly: !checkEdit('a_end.device_and_port', row.editable_fields)}"
                              :type="checkEdit('a_end.device_and_port', row.editable_fields) ? validate.a_end.device_and_port?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields.a_end?.device_and_port?? '-'"
                              name="a_end.device_and_port"
                              :readonly="!checkEdit('a_end.device_and_port', row.editable_fields)"
                              @keyup="ctrlInput('a_end.device_and_port')"
                            />
                            <p class="error error-a_end.device_and_port" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                        </div>
                        <div class="mdl-cell mdl-cell--6-col title">{{ $t("crossConnects.patchPannelMMR") }}</div>
                        <div class="mdl-cell mdl-cell--6-col">
                          <div>
                            <input
                              style="width: 95%;"
                              class="inputOrder"
                              :class="{readonly: !checkEdit('a_end.patch_panel_to_mmr', row.editable_fields)}"
                              :type="checkEdit('a_end.patch_panel_to_mmr', row.editable_fields) ? validate.a_end.patch_panel_to_mmr?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields.a_end?.patch_panel_to_mmr?? '-'"
                              name="a_end.patch_panel_to_mmr"
                              :readonly="!checkEdit('a_end.patch_panel_to_mmr', row.editable_fields)"
                              @keyup="ctrlInput('a_end.patch_panel_to_mmr')"
                            />
                            <p class="error error-a_end.patch_panel_to_mmr" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mdl-cell mdl-cell--2-col"></div>
                    <div class="mdl-cell mdl-cell--5-col boxXC mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                      <div class="mdl-grid">
                        <div class="mdl-cell mdl-cell--12-col">
                          <h3>B-END</h3>
                          <hr />
                        </div>
                        <div class="mdl-cell mdl-cell--6-col title">{{ $t("crossConnects.place") }}</div>
                        <div class="mdl-cell mdl-cell--6-col value">{{ row.category_specific_fields.b_end?.room?? '-' }}</div>
                        <div class="mdl-cell mdl-cell--6-col title">{{ $t("crossConnects.isle") }}</div>
                        <div class="mdl-cell mdl-cell--6-col value">{{ row.category_specific_fields.b_end?.islet?? '-' }}</div>
                        <div class="mdl-cell mdl-cell--6-col title">Rack</div>
                        <div class="mdl-cell mdl-cell--6-col value">{{ row.category_specific_fields.b_end?.rack?? '-' }}</div>
                        <div class="mdl-cell mdl-cell--6-col title">LOA</div>
                        <div class="mdl-cell mdl-cell--6-col" style="text-align: right;">
                          <span v-if="row.category_specific_fields.b_end?.loa_name">
                            <span @click="downloadFile(row.category_specific_fields.b_end?.loa, row.category_specific_fields.b_end?.loa_name)" class="value">{{ row.category_specific_fields.b_end.loa_name }}</span>
                          </span>
                          <span v-else class="value">-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- FINE XC -->

                <!-- ACCESSO -->
                <div v-if="typeSolution == 1" class="padding30">
                  <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
                      style="width: auto;">
                      <h4>{{ $t("checkCoverage.titleReferences") }}</h4>
                    </div>
                  </div>
                  <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--12-col"><span class="labelOne">{{ $t("checkCoverageSummary.customerDetails") }}</span></div>
                    <div class="mdl-cell mdl-cell--4-col">
                      <span class="title">{{ $t("checkCoverage.customer") }}</span>
                      <!-- <span class="value brMobile">Cliente nuovo</span> -->
                      <span class="brMobile">
                        <input
                              class="inputOrder border width50"
                              :class="{readonly: !checkEdit('end_customer', row.editable_fields)}"
                              :type="checkEdit('end_customer', row.editable_fields) ? validate.end_customer.company_name?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.end_customer?.company_name?? '-'"
                              name="end_customer.company_name"
                              :readonly="!checkEdit('end_customer', row.editable_fields)"
                              @keyup="ctrlInput('end_customer.company_name')"
                            />
                            <p class="error error-end_customer.company_name" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>
                    </div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.customerEndPIva") }}</span>
                      <!-- <span class="value brMobile">123456</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('end_customer', row.editable_fields)}"
                              :type="checkEdit('end_customer', row.editable_fields) ? validate.end_customer.vat_id?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.end_customer?.vat_id?? '-'"
                              name="end_customer.vat_id"
                              :readonly="!checkEdit('end_customer', row.editable_fields)"
                              @keyup="ctrlInput('end_customer.vat_id')"
                            />
                            <p class="error error-end_customer.vat_id" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>
                    </div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.customerEndFiscalCode") }}</span>
                      <!-- <span class="value brMobile">-</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('end_customer', row.editable_fields)}"
                              :type="checkEdit('end_customer', row.editable_fields) ? validate.end_customer.cf?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.end_customer?.cf?? '-'"
                              name="end_customer.cf"
                              :readonly="!checkEdit('end_customer', row.editable_fields)"
                              @keyup="ctrlInput('end_customer.cf')"
                            />
                            <p class="error error-end_customer.cf" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>
                    </div>
                  </div>
                  <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--12-col"><span class="labelOne">{{ $t("checkCoverage.techRef") }}</span></div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
                      <!-- <span class="value brMobile">Prova 1</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[0]'].name?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="contacts[0].name?? '-'"
                              :minlength="checkEdit('contacts', row.editable_fields) ? validate['contacts[0]'].name.minLength ? validate['contacts[0]'].name.minLength : '' : ''"
                              :maxlength="checkEdit('contacts', row.editable_fields) ? validate['contacts[0]'].name.maxlength ? validate['contacts[0]'].name.maxlength : '' : ''"
                              name="contacts.0.name"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[0].name"
                              @keyup="ctrlInput('contacts.0.name')"
                            />
                            <p class="error error-contacts.0.name" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>
                    </div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.mobile") }}</span>
                      <!-- <span class="value brMobile">11111111</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[0]'].phone?.type == 'string' ? 'text' : 'number' : 'text'"
                              :pattern="checkEdit('contacts', row.editable_fields) ? validate['contacts[0]'].phone.pattern?? '' : ''"
                              :value="contacts[0].phone?? '-'"
                              name="contacts.0.phone"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[0].phone"
                              @keyup="ctrlInput('contacts.0.phone')"
                            />
                            <p class="error error-contacts.0.phone" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>
                    </div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.email") }}</span>
                      <!-- <span class="value brMobile">prova1@cdlan.it</span> -->             
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[0]'].email?.type == 'string' ? 'text' : 'number' : 'text'"
                              :pattern="checkEdit('contacts', row.editable_fields) ? validate['contacts[0]'].email.pattern?? '' : ''"
                              :value="contacts[0].email?? '-'"
                              name="contacts.0.email"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[0].email"
                              @keyup="ctrlInput('contacts.0.email')"
                            />
                            <p class="error error-contacts.0.email" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>       
                    </div>
                  </div>
                  <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--12-col"><span class="labelOne">{{ $t("checkCoverage.administratorRef") }}</span></div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
                      <!-- <span class="value brMobile">Prova 2</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[1]'].name?.type == 'string' ? 'text' : 'number' : 'text'"
                              :minlength="checkEdit('contacts', row.editable_fields) ? validate['contacts[1]'].name.minLength ? validate['contacts[1]'].name.minLength : '' : ''"
                              :maxlength="checkEdit('contacts', row.editable_fields) ? validate['contacts[1]'].name.maxlength ? validate['contacts[1]'].name.maxlength : '' : ''"
                              :value="contacts[1].name?? '-'"
                              name="contacts.1.name"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[1].name"
                              @keyup="ctrlInput('contacts.1.name')"
                            />
                            <p class="error error-contacts.1.name" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>  
                    </div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.mobile") }}</span>
                      <!-- <span class="value brMobile">2222222222</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[1]'].phone?.type == 'string' ? 'text' : 'number' : 'text'"
                              :pattern="checkEdit('contacts', row.editable_fields) ? validate['contacts[1]'].phone.pattern?? '' : ''"
                              :value="contacts[1].phone ? validate['contacts[1]'].phone?.type == 'string' ? String(contacts[1].phone) : Number(contacts[1].phone) : '-'"
                              name="contacts.1.phone"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[1].phone"
                              @keyup="ctrlInput('contacts.1.phone')"
                            />
                            <p class="error error-contacts.1.phone" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>  
                    </div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.email") }}</span>
                      <!-- <span class="value brMobile">prova2@cdlan.it</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[1]'].email?.type == 'string' ? 'text' : 'number' : 'text'"
                              :pattern="checkEdit('contacts', row.editable_fields) ? validate['contacts[1]'].email.pattern?? '' : ''"
                              :value="contacts[1].email ? validate['contacts[1]'].email.type == 'string' ? String(contacts[1].email) : Number(contacts[1].email) : '-'"
                              name="contacts.1.email"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[1].email"
                              @keyup="ctrlInput('contacts.1.email')"
                            />
                            <p class="error error-contacts.1.email" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>  
                    </div>
                  </div>
                  <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--12-col"><span class="labelOne">{{ $t("checkCoverage.onSiteRef") }}</span></div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
                      <!-- <span class="value brMobile">Prova 3</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[2]'].name?.type == 'string' ? 'text' : 'number' : 'text'"
                              :minlength="checkEdit('contacts', row.editable_fields) ? validate['contacts[2]'].name.minLength ? validate['contacts[2]'].name.minLength : '' : ''"
                              :maxlength="checkEdit('contacts', row.editable_fields) ? validate['contacts[2]'].name.maxlength ? validate['contacts[2]'].name.maxlength : '' : ''"
                              :value="contacts[2].name ? validate['contacts[2]'].name?.type == 'string' ? String(contacts[2].name) : Number(contacts[2].name) : '-'"
                              name="contacts.2.name"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[2].name"
                              @keyup="ctrlInput('contacts.2.name')"
                            />
                            <p class="error error-contacts.2.name" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>  
                    </div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.mobile") }}</span>
                      <!-- <span class="value brMobile">333333333</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[2]'].phone.type == 'string' ? 'text' : 'number' : 'text'"
                              :pattern="checkEdit('contacts', row.editable_fields) ? validate['contacts[2]'].phone.pattern?? '' : ''"
                              :value="contacts[2].phone ? validate['contacts[2]'].phone.type == 'string' ? String(contacts[2].phone) : Number(contacts[2].phone) : '-'"
                              name="contacts.2.phone"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[2].phone"
                              @keyup="ctrlInput('contacts.2.phone')"
                            />
                            <p class="error error-contacts.2.phone" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>  
                    </div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("checkCoverage.email") }}</span>
                      <!-- <span class="value brMobile">prova3@cdlan.it</span> -->
                      <span class="brMobile">
                        <input
                            class="inputOrder border width50"
                              :class="{readonly: !checkEdit('contacts', row.editable_fields)}"
                              :type="checkEdit('contacts', row.editable_fields) ? validate['contacts[2]'].email.type == 'string' ? 'text' : 'number' : 'text'"
                              :pattern="checkEdit('contacts', row.editable_fields) ? validate['contacts[2]'].email.pattern?? '' : ''"
                              :value="contacts[2].email ? validate['contacts[2]'].email.type == 'string' ? String(contacts[2].email) : Number(contacts[2].email) : '-'"
                              name="contacts.2.email"
                              :readonly="!checkEdit('contacts', row.editable_fields)"
                              v-model="contacts[2].email"
                              @keyup="ctrlInput('contacts.2.email')"
                            />
                            <p class="error error-contacts.2.email" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                      </span>  
                    </div>
                  </div>
                </div>
                <!-- FINE ACCESSO -->

                <div class="mdl-grid" :style="typeSolution == 1 ? {} : {padding: '30px 30px 0 30px'}">
                  <div class="mdl-cell mdl-cell--12-col">
                    <hr />
                  </div>
                </div>

                <div class="mdl-grid" :style="typeSolution == 1 ? !IsMobile ? {paddingLeft: '30px'} : {paddingLeft: '0px'} : {padding: '30px 30px 0 30px'}">
                  <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
                    style="width: auto;">
                    <h4>{{ $t("checkCoverage.titleAdditionalInformation") }}</h4>
                  </div>
                </div>

                <!-- XC -->
                <div v-if="typeSolution == 2">
                  <div class="mdl-grid" style="padding: 30px 30px 0 30px">
                    <div class="mdl-cell mdl-cell--12-col"><span class="labelOne">{{ $t("crossConnects.orderDetails") }}</span></div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("crossConnects.notes") }}</span><span class="value brMobile" style="word-wrap: break-word;">{{ data.notes?? '-' }}</span></div>
                  </div>
                  <div class="mdl-grid" style="padding: 30px 30px 0 30px">
                    <div class="mdl-cell mdl-cell--12-col"><span class="labelOne">{{ $t("crossConnects.crossConnectReferral") }}</span></div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("crossConnects.fullName") }}</span><span class="value brMobile">{{ row.category_specific_fields.contact ? row.category_specific_fields.contact.first_name + ' ' + row.category_specific_fields.contact.last_name : '-' }}</span></div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("crossConnects.crossConnectReferrerPhone") }}</span><span class="value brMobile">{{ row.category_specific_fields.contact?.phone?? '-' }}</span></div>
                    <div class="mdl-cell mdl-cell--4-col"><span class="title">{{ $t("crossConnects.crossConnectReferrerMail") }}</span><span class="value brMobile">{{ row.category_specific_fields.contact?.email?? '-' }}</span></div>
                  </div>
                </div>

                <!-- FINE XC -->

                <!-- ACCESSO -->
                <div v-if="typeSolution == 1">
                  <div class="contentRelatedServices additionalInfo mobileVersion">
                    <div class="mdl-grid">
                      <div class="mdl-cell mdl-cell--12-col">
                        <h3>{{ $t("checkCoverage.titleStep3") }}</h3>
                      </div>
                    </div>
                    <!-- ACCOUNT RADIUS -->
                    <div class="contentTableFlex orderPage">
                      <div class="singleElm">
                        <div><span class="title">Username</span></div>
                        <div>
                          <div>
                            <input
                              class="inputOrder width80"
                              :class="{readonly: !checkEdit('radius_account', row.editable_fields)}"
                              :type="checkEdit('radius_account', row.editable_fields) ? validate.radius_account?.username?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.radius_account?.username ? validate.radius_account?.username?.type == 'string' ? String(row.category_specific_fields?.radius_account?.username) : Number(row.category_specific_fields?.radius_account?.username) : '-'"
                              name="radius_account.username"
                              :readonly="!checkEdit('radius_account', row.editable_fields)"
                              @keyup="ctrlInput('radius_account.username')"
                            />
                            <p class="error error-radius_account.username" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                          <!-- <p class="value">{{ row.category_specific_fields.radius_account?.username?? '-' }}</p> -->
                        </div>
                      </div>
                      <div class="singleElm">
                        <div><span class="title">Password</span></div>
                        <div>
                          <div>
                            <input
                              class="inputOrder width80"
                              :class="{readonly: !checkEdit('radius_account', row.editable_fields)}"
                              :type="checkEdit('radius_account', row.editable_fields) ? validate.radius_account?.password?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.radius_account?.password ? validate.radius_account?.password?.type == 'string' ? String(row.category_specific_fields?.radius_account?.password) : Number(row.category_specific_fields?.radius_account?.password) : '-'"
                              name="radius_account.password"
                              :readonly="!checkEdit('radius_account', row.editable_fields)"
                              @keyup="ctrlInput('radius_account.password')"
                            />
                            <p class="error error-radius_account.password" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                          <!-- <p class="value">{{ row.category_specific_fields.radius_account?.password?? '-' }}</p> -->
                        </div>
                      </div>
                      <div class="singleElm">
                        <div><span class="title">{{ $t("checkCoverage.noteRadius") }}</span></div>
                        <div>
                          <div>
                            <input
                              class="inputOrder width80"
                              :class="{readonly: !checkEdit('radius_account', row.editable_fields)}"
                              :type="checkEdit('radius_account', row.editable_fields) ? validate.radius_account?.notes?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.radius_account?.notes ? validate.radius_account?.notes?.type == 'string' ? String(row.category_specific_fields?.radius_account?.notes) : Number(row.category_specific_fields?.radius_account?.notes) : '-'"
                              name="radius_account.notes"
                              :readonly="!checkEdit('radius_account', row.editable_fields)"
                              @keyup="ctrlInput('radius_account.notes')"
                            />
                            <p class="error error-radius_account.notes" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                          <!-- <p class="value">{{ row.category_specific_fields.radius_account?.notes?? '-' }}</p> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- DELIVERY -->
                  <div class="contentRelatedServices additionalInfo mobileVersion">
                    <div class="mdl-grid">
                      <div class="mdl-cell mdl-cell--12-col">
                        <h3>{{ $t("checkCoverage.delivery") }}</h3>
                      </div>
                    </div>
                    <div class="contentTableFlex orderPage">
                      <div class="singleElm">
                        <div><span class="title">{{ $t("checkCoverage.buildingFloor") }}</span></div>
                        <div>
                          <div>
                            <input
                              class="inputOrder width80"
                              :class="{readonly: !checkEdit('delivery_details', row.editable_fields)}"
                              :type="checkEdit('delivery_details', row.editable_fields) ? validate.delivery_details?.building_floor?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.delivery_details?.building_floor ? validate.delivery_details?.building_floor?.type == 'string' ? String(row.category_specific_fields?.delivery_details?.building_floor) : Number(row.category_specific_fields?.delivery_details?.building_floor) : '-'"
                              name="delivery_details.building_floor"
                              :readonly="!checkEdit('delivery_details', row.editable_fields)"
                              @keyup="ctrlInput('delivery_details.building_floor')"
                            />
                            <p class="error error-delivery_details.building_floor" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                          <!-- <p class="value">{{ row.category_specific_fields.delivery_details?.building_floor?? '-' }}</p> -->
                        </div>
                      </div>
                      <div class="singleElm">
                        <div><span class="title">{{ $t("checkCoverage.timeSlot") }}</span></div>
                        <div>
                          <div>
                            <input
                              class="inputOrder width80"
                              :class="{readonly: !checkEdit('delivery_details', row.editable_fields)}"
                              :type="checkEdit('delivery_details', row.editable_fields) ? validate.delivery_details?.time_slot?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.delivery_details?.time_slot ? validate.delivery_details?.time_slot?.type == 'string' ? String(row.category_specific_fields?.delivery_details?.time_slot) : Number(row.category_specific_fields?.delivery_details?.time_slot) : '-'"
                              name="delivery_details.time_slot"
                              :readonly="!checkEdit('delivery_details', row.editable_fields)"
                              @keyup="ctrlInput('delivery_details.time_slot')"
                            />
                            <p class="error error-delivery_details.time_slot" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                          <!-- <p class="value">{{ row.category_specific_fields.delivery_details?.time_slot?? '-' }}</p> -->
                        </div>
                      </div>
                      <div class="singleElm">
                        <div><span class="title">{{ $t("checkCoverage.noteInfo") }}</span></div>
                        <div>
                          <div>
                            <input
                              class="inputOrder width80"
                              :class="{readonly: !checkEdit('delivery_details', row.editable_fields)}"
                              :type="checkEdit('delivery_details', row.editable_fields) ? validate.delivery_details?.notes?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields?.delivery_details?.notes ? validate.delivery_details?.notes?.type == 'string' ? String(row.category_specific_fields?.delivery_details?.notes) : Number(row.category_specific_fields?.delivery_details?.notes) : '-'"
                              name="delivery_details.notes"
                              :readonly="!checkEdit('delivery_details', row.editable_fields)"
                              @keyup="ctrlInput('delivery_details.notes')"
                            />
                            <p class="error error-delivery_details.notes" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                          <!-- <p class="value">{{ row.category_specific_fields.delivery_details?.notes?? '-' }}</p> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- MIGRAZIONE OLO2OLO -->
                  <div class="contentRelatedServices additionalInfo mobileVersion">
                    <div class="mdl-grid">
                      <div class="mdl-cell mdl-cell--12-col">
                        <h3>{{ $t("checkCoverage.titleStep5") }}</h3>
                      </div>
                    </div>
                    <div class="contentTableFlex orderPage">
                      <div class="singleElm">
                        <div><span class="title">{{ $t("checkCoverage.migrationCode") }}</span></div>
                        <div>
                          <div>
                            <input
                              class="inputOrder width80"
                              :class="{readonly: !checkEdit('olo2olo_migration', row.editable_fields)}"
                              :type="checkEdit('olo2olo_migration', row.editable_fields) ? validate.olo2olo_migration?.code?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields.olo2olo_migration?.code?? '-'"
                              name="olo2olo_migration.code"
                              :readonly="!checkEdit('olo2olo_migration', row.editable_fields)"
                              @keyup="ctrlInput('delivery_details.code')"
                            />
                            <p class="error error-delivery_details.code" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                          <!-- <p class="value">{{ row.category_specific_fields.olo2olo_migration?.code?? '-' }}</p> -->
                        </div>
                      </div>
                      <div class="singleElm">
                        <div><span class="title">{{ $t("checkCoverage.migrationNote") }}</span></div>
                        <div>
                          <div>
                            <input
                              class="inputOrder width80"
                              :class="{readonly: !checkEdit('olo2olo_migration', row.editable_fields)}"
                              :type="checkEdit('olo2olo_migration', row.editable_fields) ? validate.olo2olo_migration?.notes?.type == 'string' ? 'text' : 'number' : 'text'"
                              :value="row.category_specific_fields.olo2olo_migration?.notes?? '-'"
                              name="olo2olo_migration.notes"
                              :readonly="!checkEdit('olo2olo_migration', row.editable_fields)"
                              @keyup="ctrlInput('delivery_details.notes')"
                            />
                            <p class="error error-delivery_details.notes" style="display: none;">{{ $t("detailOrder.errorInput") }}</p>
                          </div>
                          <!-- <p class="value">{{ row.category_specific_fields.olo2olo_migration?.notes?? '-' }}</p> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="contentRelatedServices additionalInfo mobileVersion">
                    <div class="mdl-grid">
                      <div class="mdl-cell mdl-cell--12-col">
                        <h3>{{ $t("checkCoverage.technicalDetails") }}</h3>
                      </div>
                    </div>
                    <div class="contentTableFlex orderPage">
                      <div class="singleElm">
                        <div><span class="value" style="margin-left: 0px;">{{ data.notes?? '-' }}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- FINE ACCESSO -->

              <!-- FINE BLOCCO -->
             <br />
          </div>
          </div>
        </div>
        </div>
      </div>
      <p class="error" v-if="viewError" style="text-align: center;" v-html="$t('detailOrder.errorGeneric')"></p>
      <MyLoading :viewLoader="loader" type="" />
    </div>
  </div>


</template>

<style scoped lang="scss">
button.cdlButton {
  width: auto;
  padding: 20px 40px;
}

.info-section {
  display: flex;
  align-items: center;
}

.info-container {
  display: flex;
  align-items: flex-start;
}

.info-icon {
  margin-right: 12px;
  flex-shrink: 0;
  width: 2%;
}

.info-icon-mobile {
  margin-right: 12px;
  flex-shrink: 0;
}

.info-text {
  color: #8895a7;
  line-height: 1.5;
}
</style>


<script>
import MyLoading from "@/components/MyLoading.vue";
import "@/assets/scss/OrderNetworkCoverageSummary.scss";
import moment from "moment";
import {
  createAnchorForDownload,
  removeAnchor,
} from "@/services/DownloadFile.js";


export default {
  name: "OrderNetworkCoverageSummary",
  components: {
    MyLoading
  },
  data() {
    return {
      typeSolution: 1,
      IsMobile: this.$IsMobile(),
      data: {},
      loader: false,
      viewError: false,
      viewModal: false,
      viewModalClose: false,
      schema: [],
      canEdit: false,
      validate: {},
      contacts: [
        {
          type: "technical",
          name: null,
          email: null,
          phone: null,
        },
        {
          type: "administrative",
          name: null,
          email: null,
          phone: null,
        },
        {
          type: "on-site",
          name: null,
          email: null,
          phone: null,
        },
      ],
      serial_number: "",
      error: false
    };
  },
  methods: {
    buildPage( order_id ) {
      this.viewError = false;
      const self = this;
      this.axios
        .get(
          `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}sales/v1/order/${
            order_id
          }`,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.$keycloak.idToken,
            },
          }
        )
        .then((response) => {
          console.log(response)
          this.loader = true;
          if( response.status == 200 ) {
            this.data = response.data;
            this.buildEditFields();
          }
          else {
            this.viewError = true;
          }

        })
        .catch((err) => {
          console.log(err)
          self.viewError = true;
          window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
        });
    },
    getPrice(price) {
      return this.$PriceClean(price ?? 0);
    },
    getDate(myTime) {
      return moment(myTime).format("DD/MM/YYYY", this.$i18n.locale, true);
    },
    getSize(value) {
      let rls = value + "Mbps";
      if (value >= 1000) {
        rls = value / 1000 + "Gbps";
      }
      return rls;
    },
    checkEdit( field, fields ) {
      if( !this.canEdit ) {
        return false;
      }
      if( fields.value?.short ) {
        let value = fields.value.short;
        return value.some(obj => obj.name === field);
      }
      return false;
    },
    buildContacts() {
      let rows = this.data.rows;
      rows.forEach(elm => {
        if( elm.category_specific_fields?.contacts?.length > 0 ) {
          let contacts = elm.category_specific_fields?.contacts;
          contacts.forEach(contact => {
            let index = 0;
            switch( contact.type ) {
              case 'administrative':
                index = 1;
                break;
              case 'on-site':
                index = 2;
                break;
              default:
                index = 0;
            }
            this.contacts[index].name = contact.name;
            this.contacts[index].email = contact.email;
            this.contacts[index].phone = contact.phone;
          });
        }
      });
    },
    buildEditFields() {
      let rows = this.data.rows;
      let ctrlState = true;
      rows.forEach(elm => {
        if( Object.keys(elm.editable_fields).length > 0 ) {
          if( ctrlState ) {
            ctrlState = false;
            this.canEdit = elm.state != 'INTERVENTO UTENTE RICHIESTO' ? false : true
            if( this.data.category == "XCONNECT" ) {
              this.typeSolution = 2
            }
            else {
              this.buildContacts();
            }
          }
          this.serial_number = elm.serial_number;
          let schema = elm.editable_fields.value.schema.properties;
          this.generateInputs(schema);
        }
      });
    },
    generateInputs(schema, parentName = '') {
      for (const key in schema) {
        const fieldName = parentName ? `${parentName}.${key}` : key;
        const field = schema[key];

        if (field.type === "object") {
          // console.log("1", field.properties, fieldName);
          this.validate[fieldName] = field.properties;
        } else if (field.type === "array" && field.items.type === "object") {
          for (let i = 0; i < (field.maxItems || 1); i++) {
            const groupName = `${fieldName}[${i}]`;
            // console.log("2", field.items.properties, groupName);
            this.validate[groupName] = field.items.properties;
          }
        } else if (field.type === "array") {
          // const input = createInput(key, fieldName, "text");
          // console.log("3", key, fieldName, "text");
          this.validate[fieldName] = key;
        } else {
          console.log("4",          
            key,
            fieldName,
            field.type === "string" ? "text" : field.type,
            field.pattern || null,
            field.required || false,
            field.enum || null);
          // const input = createInput(
          //   key,
          //   fieldName,
          //   field.type === "string" ? "text" : field.type,
          //   field.pattern || null,
          //   field.required || false,
          //   field.enum || null
          // );
          // form.appendChild(input);
        }
      }
    },
    async downloadFile(file_id, filename) {
      try {
        const response = await this.axios({
          url: `${this.$MyCrypto(
            window.VUE_APP_MISTRA_URL,
            1
          )}files/v1/download/loa/${file_id}`,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.$keycloak.idToken,
          },
        });

        //create temp element to
        const anchorElement = createAnchorForDownload(
          response.data,
          true,
          filename
        );
        // simulate click
        anchorElement.click();
        // remove the element
        removeAnchor(anchorElement);
      } catch (error) {
        console.log("error: ", error);
      }
    },
    async submit() {
      if( this.error ) {
        return;
      }
      let elms = document.querySelectorAll('input');
      let inputs = [];
      elms.forEach((e) => {
        inputs.push(e.name + ":" + e.value + ":" + e.type);
      });
      const result = inputs.reduce((obj, str) => {
                          const [key, value, type] = str.split(':');
                          const path = key.split('.'); 
                          if (!obj[path[0]]) obj[path[0]] = {};
                          if( type == "text" && value ) {
                            obj[path[0]][path[1]] = String(value);
                          }
                          else if( type == "number" && value ) {
                            obj[path[0]][path[1]] = Number(value);
                          }
                          else {
                            obj[path[0]][path[1]] = value; //isNaN(value) ? value : parseInt(value, 10);
                          }
                          return obj;
                      }, {});
      // result['order_id'] = this.data.id;
      result['contacts'] = this.contacts;



      // result.forEach(elm => {
      //   console.log(elm);
      // })

      // let myResult = [];
      // myResult.push({network_access_editable_fields: result});

      
      // let myResult = {};
      // myResult.network_access_editable_fields = result;

      // let myResult = {
      //   "contacts": [
      //     {
      //       "type": "technical",
      //       "name": "John Doe",
      //       "email": "john.doe@example.com",
      //       "phone": "1234567890"
      //     },
      //     {
      //       "type": "administrative",
      //       "name": "Jane Smith",
      //       "email": "jane.smith@example.com",
      //       "phone": "0987654321"
      //     },
      //     {
      //       "type": "on-site",
      //       "name": "Richard Roe",
      //       "email": "richard.roe@example.com",
      //       "phone": "1122334455"
      //     }
      //   ],
      //   "end_customer": {
      //     "company_name": "ACME Corp",
      //     "vat_id": "IT12345678901"
      //   },
      //   "radius_account": {
      //     "username": "radius_user",
      //     "password": "securepassword",
      //     "notes": "Account for secure access"
      //   },
      //   "delivery_details": {
      //     "building_floor": "5A",
      //     "time_slot": "9:00 AM - 12:00 PM",
      //     "notes": "Leave at the reception desk"
      //   },
      //   "olo2olo_migration": {
      //     "code": "MIG12345",
      //     "notes": "Migration scheduled for Q2"
      //   }
      // };

      // Object.assign(this.data, result);
      // console.log(myResult);
      console.log(result);

      const self = this;
      this.axios
        .put(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + 'sales/v1/order/' + this.data.id + '/' + this.serial_number,
        result,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.$keycloak.idToken,
            },
          }
        )
        .then((response) => {
          console.log(response)

        })
        .catch((err) => {
          console.log(err)
          self.viewError = true;
          window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
        });

    },
    ctrlInput( name ) {
      let input = document.getElementsByName(name)[0];
      let error = 'none';
      this.error = false;
      if( input.minLength && input.value.length < input.minLength ) {
        error = "block";
        this.error = true;
      }
      if( input.pattern ) {
        let regex = new RegExp(input.pattern);
        if( !regex.test(input.value) ) {
          error = "block";
          this.error = true;
        }
      }
      if( !input.value ) {
        error = "block";
        this.error = true;
      }
      document.getElementsByClassName('error-' + name)[0].style.display = error;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.buildPage(this.$route.params.order_id);
  },
  // beforeUpdate() {
  //   this.$IsLoggedIn();
  //   this.buildPage(this.$route.params.order_id);
  // },
};
</script>
