<script setup>
import MyCrossStep1 from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep1.vue";
import MyCrossStep2 from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep2.vue";
import MyCrossStep3 from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep3.vue";
import MyCrossStep4 from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep4.vue";
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue";
import HorisontalArrow from "@/components/OrderNetworkCoverage/svg/cross-svg/HorisontalArrow.vue";
import RedundancyComponent from "@/components/CrossConnect/ToggleSwitch.vue";
import CrossStep from "@/components/CrossConnect/CrossStep.vue";
import CrossCard from "@/components/CrossConnect/CrossCard.vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";
import CrossStep1InCorso from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep1InCorso.vue";
import CrossStep1Completato from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep1Completato.vue";
import CrossStep2InCorso from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep2InCorso.vue";
import CrossStep3InCorso from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep3InCorso.vue";
import CrossStep4InCorso from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep4InCorso.vue";
import CrossStep2Completato from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep2Completato.vue";
import CrossStep3Completato from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep3Completato.vue";
import CrossStep4Completato from "@/components/OrderNetworkCoverage/svg/cross-svg/CrossStep4Completato.vue";
import MyCrossRefTicket from "@/components/Users/CrossRefTicket.vue";
import CrossCardInfra from "@/components/CrossConnect/CrossCardInfra.vue";
import MyLoading from "@/components/MyLoading.vue";
import { useRouter } from "vue-router";
import AEndComponent from "@/components/CrossConnect/CrossFormComponents/AEndComponent";
import BEndComponentLocal from "@/components/CrossConnect/CrossFormComponents/BEndComponentLocal";
import FormsHeader from "@/components/CrossConnect/Title/FormsHeader";
import ButtonsGroupComponent from "@/components/CrossConnect/Buttons/ButtonsGroupComponent.vue"; // Access the global properties directly within setup

// Access the global properties directly within setup
const { config } = getCurrentInstance().appContext;
const isMobile = ref(false);
const datacenterStore = useDatacenterStore();
const viewLoader = ref(true);
const router = useRouter();
const isCardSelected = ref(false);
const showContent = ref(false);

// Refs for selected IDs
const selectedDatacenterId = ref(null);
const selectedRoomIdB = ref(null);
const selectedIslandId = ref(null);
const selectedRackIdB = ref(null);
// const selectedPatchPannel = ref(null);
const isDataCenterCardSelected = ref(
  datacenterStore.isDataCenterCardSelected ?? false
);
const isKitSelected = ref(datacenterStore.isKitSelected ?? false);

// Computed properties for rooms, racks, and rack units
const selectedDatacenterRoomsA = computed(
  () => datacenterStore.rooms[datacenterStore.datacenter] || []
);

onMounted(async () => {
  window.scrollTo(0, 0);
  if (datacenterStore.isFromvalidation) {
    currentStep.value = datacenterStore.pageNumber;
  }
  isMobile.value = config.globalProperties.$IsMobile();
  // console.log("datacenterStore.info", datacenterStore.info);

  try {
    if (!datacenterStore.info || datacenterStore.isCartItem) {
      await Promise.all([datacenterStore.fetchDatacenterInfo()]);
      datacenterStore.infracampus.isInfra = false;
    }
  } catch (e) {
    console.error("Error during fetch operations:", e);
  } finally {
    // Set viewLoader to false after all operations are complete
    viewLoader.value = false;
  }
});
// Watchers
watch(selectedDatacenterId, (newId) => {
  if (newId) {
    datacenterStore.fetchRooms(newId);
    datacenterStore.datacenter = selectedDatacenterId;
  }
});

const handleToggleChanged = (value) => {
  showContent.value = value;
  if (showContent.value === true) {
    datacenterStore.redundancy = true;
    datacenterStore.rmrc = datacenterStore.mrc;
    datacenterStore.rnrc = datacenterStore.nrc;
  } else {
    datacenterStore.redundancy = false;
  }
};
const handleToggleChangedInsideCard = () => {
  console.log("triggered");
  if (datacenterStore.rbend.is_my_rack === true) {
    datacenterStore.redunandLoaName = null;
    datacenterStore.attachment_rbend = {};
  } else {
    datacenterStore.rackBNameR = "";
    datacenterStore.roomBNameR = "";
    datacenterStore.islandNameR = "";
    datacenterStore.rbend.cage = null;
    datacenterStore.rbend.rack = null;
    datacenterStore.rbend.island = null;
  }
  if (datacenterStore.bend.is_my_rack === true) {
    datacenterStore.loaName = null;
    datacenterStore.attachment_b_end = {};
  } else {
    datacenterStore.rackBName = "";
    datacenterStore.roomBName = "";
    datacenterStore.islandName = "";
    datacenterStore.bend.cage = null;
    datacenterStore.bend.rack = null;
    datacenterStore.bend.island = null;
  }
};

const handleRadioSelect = (selectedName) => {
  const firstThreeChars = selectedName.slice(0, 3);

  const selectedDataCenter = datacenterStore.info.find((dc) =>
    dc.name.includes(firstThreeChars)
  );
  datacenterStore.boxData = selectedDataCenter;
  if (selectedDataCenter) {
    selectedDatacenterId.value = selectedDataCenter.id;
    datacenterStore.fetchKitsXconn(selectedDataCenter.id, 1);
    isCardSelected.value = true; // Set to true if any data center is selected
    datacenterStore.dc_address = datacenterStore.info[0].address;
    datacenterStore.nameXC = datacenterStore.info[0].name;
  } else {
    isCardSelected.value = false; // Reset if no valid selection
  }
};

const handleRadioSelectKit = (selectedName) => {
  isKitSelected.value = true;
  const kitProduct =
    datacenterStore.xConInfo.find(
      (kit) => kit.related_products[0].group_name === selectedName
    ) || {};
  if (kitProduct) {
    // console.log("kitProductLocal:", kitProduct);

    datacenterStore.kitRelatedProduct = kitProduct;
    datacenterStore.xc_article = kitProduct.title;
    datacenterStore.mrc = kitProduct.related_products[0].products[0].price.mrc;
    datacenterStore.nrc = kitProduct.related_products[0].products[0].price.nrc;
  }
};

const goToStep = (value) => {
  // console.log("entered goToStep with value: ", value);
  // Scroll to the top of the page
  window.scrollTo(0, 0);

  // start validation
  datacenterStore.isValidationCheckInitiated = true;

  // validate Datacenter/Type step
  if (value === 2) {
    // this step should validate if datacenter is selected
    if (!isCardSelected.value && !isKitSelected.value) {
      return;
    }

    // this step should validate if XConn type is selected
    if (isCardSelected.value && !isKitSelected.value) {
      // Reset if no valid selection
      isCardSelected.value = false;
      isDataCenterCardSelected.value = true;
      return;
    }
  }

  // Check if the form is valid when moving from step 3 to step 4
  if (value === 3 && !datacenterStore.localStep2IsValid) {
    // console.log("entered value 3");
    // Form is not valid, do not proceed to the next step
    // Optionally, show an error message or handle it as needed
    return;
  }
  // console.log(
  //   value,
  //   datacenterStore.localStep3IsValid,
  //   datacenterStore.redundancy
  // );
  if (
    value === 4 &&
    !datacenterStore.localStep3IsValid &&
    datacenterStore.redundancy
  ) {
    console.log("entered value 4");
    return;
  }
  if (value === 5) {
    if (
      datacenterStore.localStep2IsValid &&
      datacenterStore.localStep3IsValid
    ) {
      router.push({ name: "CrossConnectValidation" });
      datacenterStore.isValidationCheckInitiated = false;
      return;
    } else {
      return;
    }
  }

  // If validation is passed or not needed, proceed to the desired step
  currentStep.value = value;
  // Reset validation check flag after moving to the next step
  datacenterStore.isValidationCheckInitiated = false;
};

const stepsData = [
  {
    blockedIcon: MyCrossStep1,
    ongoingIcon: CrossStep1InCorso,
    completedIcon: CrossStep1Completato,
    title: "crossConnects.step1Heading",
  },
  {
    blockedIcon: MyCrossStep2,
    ongoingIcon: CrossStep2InCorso,
    completedIcon: CrossStep2Completato,
    title: "crossConnects.step2Heading",
  },
  {
    blockedIcon: MyCrossStep3,
    ongoingIcon: CrossStep3InCorso,
    completedIcon: CrossStep3Completato,
    title: "crossConnects.step3Heading",
  },
  {
    blockedIcon: MyCrossStep4,
    ongoingIcon: CrossStep4InCorso,
    completedIcon: CrossStep4Completato,
    title: "crossConnects.step4Heading",
  },
];
const currentStep = ref(1); // This will track the current step
const listSteps = computed(() => {
  return stepsData.map((step, index) => {
    let icon;
    if (index < currentStep.value - 1) {
      icon = step.completedIcon;
    } else if (index === currentStep.value - 1) {
      icon = step.ongoingIcon;
    } else {
      icon = step.blockedIcon;
    }
    return { ...step, icon };
  });
});

function setStep(value) {
  if (datacenterStore.isCartItem && value === 1) {
    return;
  }
  if (currentStep.value >= value) {
    currentStep.value = value;
  }
}

const handleFileChange = (event, isRedunant) => {
  if (isRedunant) {
    datacenterStore.handleFileChange(event, "attachment_rbend", true);
  } else {
    datacenterStore.handleFileChange(event, "attachment_bend", false);
  }
};
const handleRoomSelectionBInContainer = (key, label, isRedundant) => {
  selectedRoomIdB.value = key;
  // Find the room by id and set the name in datacenterStore
  if (isRedundant) {
    datacenterStore.rackBNameR = "";
    datacenterStore.islandNameR = "";
  } else {
    datacenterStore.rackBName = "";
    datacenterStore.islandName = "";
  }

  if (label && !isRedundant) {
    datacenterStore.roomBName = label; // Save the room name to datacenterStore
  } else if (label && isRedundant) {
    datacenterStore.roomBNameR = label;
  }
};
const handleIslandSelectionAInContainer = (key, label, isRedundant) => {
  selectedIslandId.value = key;
  if (isRedundant) {
    datacenterStore.rackBNameR = "";
  } else {
    datacenterStore.rackBName = "";
  }
  if (label && !isRedundant) {
    datacenterStore.islandName = label; // Save the room name to datacenterStore
  } else if (label && isRedundant) {
    datacenterStore.islandNameR = label;
  }
};

const handleRackSelectionBInContainer = (key, value, isRedundant) => {
  selectedRackIdB.value = key;
  // Save the rack name to datacenterStore
  if (isRedundant) {
    datacenterStore.rackBNameR = value;
  } else {
    datacenterStore.rackBName = value;
  }
};
</script>

<template>
  <div id="OrderCrossCoverage">
    <div class="mainWrapper">
      <div class="contentSteps">
        <div class="steps">
          <CrossStep
            v-for="(step, index) in listSteps"
            :key="index"
            :index="index"
            :step="currentStep"
            :listStep="step"
            @step-clicked="setStep(index + 1)"
          />
        </div>
      </div>
      <MyLoading :viewLoader="viewLoader" type="" v-if="viewLoader" />
      <div class="contentsForm" v-else>
        <FormsHeader
          :title="$t(`crossConnects.step${currentStep}Heading`)"
          :current-step="currentStep"
          :is-infra="false"
          :isDataCenterCardSelected="isDataCenterCardSelected"
        />
        <div class="mdl-grid form-container" v-if="!isMobile">
          <div class="mdl-cell mdl-cell--12-col">
            <div class="formWidth">
              <div v-if="currentStep === 1">
                <div class="sectionTitle" style="margin-top: 2.5rem"></div>
                <div
                  class="cards-container-select"
                  v-if="!isDataCenterCardSelected"
                >
                  <CrossCard
                    v-for="dataCenter in datacenterStore.info"
                    :key="dataCenter.id"
                    :id="dataCenter.name"
                    name="data_center"
                    :value="dataCenter.name"
                    :label="`${dataCenter.name}`"
                    :subtitle="`${dataCenter.name} Data Center`"
                    :address="dataCenter.address"
                    :description="`Local ${dataCenter.name}-${
                      dataCenter.name
                    } - ${$t('crossConnects.localC1C2')}`"
                    @radio-selected="handleRadioSelect"
                  />
                </div>
                <div
                  :class="[
                    'cards-container-intra',
                    datacenterStore.xConInfo.length === 1
                      ? 'single-item'
                      : 'multiple-items',
                  ]"
                  v-else
                >
                  <CrossCardInfra
                    v-for="xDdataCenter in datacenterStore.xConInfo"
                    :key="xDdataCenter.id"
                    :id="xDdataCenter.related_products[0].products[0].id"
                    name="data_center"
                    :value="xDdataCenter.related_products[0].products[0].title"
                    :label="xDdataCenter.related_products[0].products[0].title"
                    subtitle="Data Center C21 Milano"
                    address="Via Caldera, 21, 20153, Milano"
                    selectable
                    @radio-selected="handleRadioSelectKit"
                    class="cross-card"
                    :img="xDdataCenter.img_url"
                  ></CrossCardInfra>
                </div>
              </div>
              <div v-else-if="currentStep === 2">
                <div class="subtitle"></div>
                <div class="sectionTitle" style="margin-top: 2rem">
                  {{ $t("crossConnects.formSubtitleCorrispettives") }}
                </div>
                <div
                  :class="
                    isMobile
                      ? 'flex-container text-style-mobile'
                      : 'flex-container text-style'
                  "
                >
                  <span
                    >{{ $t("crossConnects.activationPrice") }}
                    <span class="second-item" style="font-weight: 400">
                      €{{ useDatacenterStore().nrc }}</span
                    ></span
                  >
                  <span
                    >{{ $t("crossConnects.monthlyPrice") }}
                    <span style="font-weight: 400">
                      €{{ useDatacenterStore().mrc }}</span
                    ></span
                  >
                </div>
                <div class="separator"></div>
                <div class="sectionTitle">
                  {{ $t("crossConnects.aendbend") }}*
                </div>
                <div
                  :class="
                    isMobile ? 'cards-container-mobile' : 'cards-container'
                  "
                >
                  <AEndComponent
                    :form-content="datacenterStore.aend"
                    :selectedDatacenterRoomsRA="selectedDatacenterRoomsA"
                    :validation-initiated="
                      datacenterStore.isValidationCheckInitiated
                    "
                    :redundant="false"
                  />
                  <div class="arrow-container">
                    <HorisontalArrow class="arrow-icon" />
                  </div>
                  <BEndComponentLocal
                    :form-content="datacenterStore.bend"
                    :selectedDatacenterRoomsRB="selectedDatacenterRoomsA"
                    :validation-initiated="
                      datacenterStore.isValidationCheckInitiated
                    "
                    :attachment="datacenterStore.attachment_b_end"
                    :redundant="false"
                    @update-room="handleRoomSelectionBInContainer"
                    @update-island="handleIslandSelectionAInContainer"
                    @update-rack="handleRackSelectionBInContainer"
                    @toggle-changed="handleToggleChangedInsideCard"
                    @update-file="handleFileChange"
                  />
                </div>
                <div
                  v-if="!datacenterStore.bend.is_my_rack"
                  :class="
                    isMobile
                      ? 'subtitle-container-mobile'
                      : 'subtitle-container'
                  "
                >
                  <div class="subtitle-text">
                    {{ $t("crossConnects.infoLOAFiles") }}
                  </div>
                  <div class="description-text">
                    {{ $t("crossConnects.authLetter") }}
                  </div>
                </div>
                <div
                  :class="
                    isMobile
                      ? 'subtitle-container-mobile'
                      : 'subtitle-container'
                  "
                >
                  <div
                    v-if="!datacenterStore.bend.is_my_rack"
                    class="subtitle-text"
                  >
                    {{ $t("crossConnects.reqLOAFiles") }}
                  </div>
                  <div
                    v-if="!datacenterStore.bend.is_my_rack"
                    class="description-text"
                  >
                    <p style="margin-top: 1rem">
                      {{ $t("crossConnects.reqLOAtext1") }}
                    </p>
                    <div>
                      {{ $t("crossConnects.reqLOAtext2") }}
                      <ul class="nested-list">
                        <li>
                          {{ $t("crossConnects.nameof") }}
                          <span class="highlight">{{
                            $t("crossConnects.society")
                          }}</span
                          >{{ $t("crossConnects.thatLOADeclares") }}
                        </li>
                        <li>
                          {{ $t("crossConnects.indicationAndPlacement") }}
                          <span class="highlight">B–END</span>
                          {{ $t("crossConnects.andOf") }}
                          <span class="highlight">{{
                            $t("crossConnects.port")
                          }}</span>
                          {{ $t("crossConnects.inWhichConnectionTerminated") }}
                        </li>
                        <li>
                          {{ $t("crossConnects.30daysValidation") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              </div>

              <div v-else-if="currentStep === 3">
                <div class="field-title second" style="margin-top: 4rem">
                  {{ $t("crossConnects.redundancyPossibility") }}
                </div>
                <RedundancyComponent
                  @toggle-changed="handleToggleChanged"
                  :togled="datacenterStore.redundancy"
                />
                <div v-if="datacenterStore.redundancy">
                  <div class="separator"></div>
                  <div class="sectionTitle">
                    {{ $t("crossConnects.redundancyActivationCosts") }}
                  </div>
                  <div class="flex-container text-style">
                    <span>{{ $t("crossConnects.activationPrice") }}</span>
                    <span class="second-item" style="font-weight: 400">
                      € {{ datacenterStore.nrc }}</span
                    >
                    <span>{{ $t("crossConnects.monthlyPrice") }}</span>
                    <span style="font-weight: 400">
                      € {{ datacenterStore.mrc }}</span
                    >
                  </div>
                  <div class="separator"></div>
                  <div class="sectionTitle">
                    {{ $t("crossConnects.aendbend") }}*
                  </div>
                  <div class="cards-container" style="gap: 3rem">
                    <AEndComponent
                      :form-content="datacenterStore.raend"
                      :selectedDatacenterRoomsRA="selectedDatacenterRoomsA"
                      :validation-initiated="
                        datacenterStore.isValidationCheckInitiated
                      "
                      :redundant="true"
                    />
                    <div class="arrow-container">
                      <HorisontalArrow class="arrow-icon" />
                    </div>
                    <BEndComponentLocal
                      :form-content="datacenterStore.rbend"
                      :selectedDatacenterRoomsRB="selectedDatacenterRoomsA"
                      :validation-initiated="
                        datacenterStore.isValidationCheckInitiated
                      "
                      :attachment="datacenterStore.attachment_rbend"
                      :redundant="true"
                      @update-room="handleRoomSelectionBInContainer"
                      @update-island="handleIslandSelectionAInContainer"
                      @update-rack="handleRackSelectionBInContainer"
                      @toggle-changed="handleToggleChangedInsideCard"
                      @update-file="handleFileChange"
                    />
                  </div>
                  <div
                    v-if="!datacenterStore.rbend.is_my_rack"
                    class="subtitle-container"
                  >
                    <div class="subtitle-text">
                      {{ $t("crossConnects.infoLOAFiles") }}
                    </div>
                    <div class="description-text">
                      {{ $t("crossConnects.authLetter") }}
                    </div>
                  </div>
                  <div
                    v-if="!datacenterStore.rbend.is_my_rack"
                    class="subtitle-container"
                  >
                    <div class="subtitle-text">
                      {{ $t("crossConnects.reqLOAFiles") }}
                    </div>
                    <div class="description-text">
                      <p style="margin-top: 1rem">
                        {{ $t("crossConnects.reqLOAtext1") }}
                      </p>
                      <div>
                        {{ $t("crossConnects.reqLOAtext2") }}
                        <ul class="nested-list">
                          <li>
                            {{ $t("crossConnects.nameof") }}
                            <span class="highlight">{{
                              $t("crossConnects.society")
                            }}</span
                            >{{ $t("crossConnects.thatLOADeclares") }}
                          </li>
                          <li>
                            {{ $t("crossConnects.indicationAndPlacement") }}
                            <span class="highlight">B–END</span>
                            {{ $t("crossConnects.andOf") }}
                            <span class="highlight">{{
                              $t("crossConnects.port")
                            }}</span>
                            {{
                              $t("crossConnects.inWhichConnectionTerminated")
                            }}
                          </li>
                          <li>
                            {{ $t("crossConnects.reqLOAtext3") }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
              </div>
              <div v-else-if="currentStep === 4">
                  <div class="sectionTitle" style="margin-top: 2rem">
                    {{ $t("crossConnects.otherInfo") }}
                  </div>
                <div
                  class="cards-container"
                  style="
                    text-align: left;
                    border-radius: 4px 4px 0px 0px;
                    gap: 3rem;
                  "
                >
                  <div class="selectable-fields" style="padding: 0">
                      <div class="field-title">
                        {{ $t("crossConnects.order") }}
                      </div>
                      <div class="field">
                        <input
                          type="text"
                          name="order-input-infra"
                          id="order-input-infra"
                          v-model="datacenterStore.purchase_order"
                          class="input-element"
                        />
                      </div>
                    <div class="field-title">
                      <label>
                        {{ $t("crossConnects.notesAndTechDetails") }}
                      </label>
                      <div>
                        <small style="font-size: 12px">{{
                          $t("fields.maxlength", { len: "255" })
                        }}</small>
                      </div>
                    </div>
                    <div class="field">
                      <textarea
                        type="text"
                        name="notes"
                        id="notes"
                        v-model="datacenterStore.description"
                        class="text-area-element"
                        maxlength="255"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="sectionTitle">
                  {{ $t("crossConnects.crossConnectReferrer") }}
                </div>
                <div class="cards-container2">
                  <MyCrossRefTicket />
                </div>
                <div class="separator"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-container-mobile" v-if="isMobile">
          <div class="formWidth">
            <div class="formWidth" v-if="currentStep === 1">
              <div class="sectionTitle" style="margin-top: 2.5rem"></div>
              <div
                class="cards-container-select-mobile"
                v-if="!isDataCenterCardSelected"
              >
                <CrossCard
                  v-for="dataCenter in datacenterStore.info"
                  :key="dataCenter.id"
                  :id="dataCenter.name"
                  name="data_center"
                  :value="dataCenter.name"
                  :label="`${dataCenter.name}`"
                  :subtitle="`${dataCenter.name} Data Center`"
                  :address="dataCenter.address"
                  :description="`Local ${dataCenter.name}-${dataCenter.name} - Coppia di fibre ottiche`"
                  @radio-selected="handleRadioSelect"
                />
              </div>
              <div
                :class="[
                  'cards-container-intra',
                  datacenterStore.xConInfo.length === 1
                    ? 'single-item'
                    : 'multiple-items',
                ]"
                v-if="isDataCenterCardSelected"
              >
                <CrossCardInfra
                  v-for="xDdataCenter in datacenterStore.xConInfo"
                  :key="xDdataCenter.id"
                  :id="xDdataCenter.related_products[0].products[0].id"
                  name="data_center"
                  :value="xDdataCenter.related_products[0].products[0].title"
                  :label="xDdataCenter.related_products[0].products[0].title"
                  subtitle="Data Center C21 Milano"
                  address="Via Caldera, 21, 20153, Milano"
                  selectable
                  @radio-selected="handleRadioSelectKit"
                  class="cross-card"
                  :img="xDdataCenter.img_url"
                ></CrossCardInfra>
              </div>
            </div>
            <div v-else-if="currentStep === 2">
              <div class="subtitle"></div>
              <div class="sectionTitle" style="margin-top: 2rem">
                {{ $t("crossConnects.formSubtitleCorrispettives") }}
              </div>
              <div
                :class="
                  isMobile
                    ? 'flex-container text-style-mobile'
                    : 'flex-container text-style'
                "
              >
                <span
                  >{{ $t("crossConnects.activationPrice") }}
                  <span class="second-item" style="font-weight: 400">
                    €{{ useDatacenterStore().nrc }}</span
                  ></span
                >
                <span
                  >{{ $t("crossConnects.monthlyPrice") }}
                  <span style="font-weight: 400">
                    €{{ useDatacenterStore().mrc }}</span
                  ></span
                >
              </div>
              <div class="separator"></div>
              <div class="sectionTitle">
                {{ $t("crossConnects.aendbend") }}*
              </div>
              <div
                :class="isMobile ? 'cards-container-mobile' : 'cards-container'"
              >
                <AEndComponent
                  :form-content="datacenterStore.aend"
                  :selectedDatacenterRoomsRA="selectedDatacenterRoomsA"
                  :validation-initiated="
                    datacenterStore.isValidationCheckInitiated
                  "
                  :redundant="false"
                />
                <div class="arrow-container">
                  <HorisontalArrow class="arrow-icon" />
                </div>
                <BEndComponentLocal
                  :form-content="datacenterStore.bend"
                  :selectedDatacenterRoomsRB="selectedDatacenterRoomsA"
                  :validation-initiated="
                    datacenterStore.isValidationCheckInitiated
                  "
                  :attachment="datacenterStore.attachment_b_end"
                  :redundant="false"
                  @update-room="handleRoomSelectionBInContainer"
                  @update-island="handleIslandSelectionAInContainer"
                  @update-rack="handleRackSelectionBInContainer"
                  @toggle-changed="handleToggleChangedInsideCard"
                  @update-file="handleFileChange"
                />
              </div>
            </div>
            <div v-else-if="currentStep === 3">
              <div class="field-title second">
                {{ $t("crossConnects.redundancyPossibility") }}
              </div>
              <RedundancyComponent
                @toggle-changed="handleToggleChanged"
                :togled="datacenterStore.redundancy"
                :isMobile="isMobile"
              />
              <div v-if="datacenterStore.redundancy">
                <div class="separator"></div>
                <div class="sectionTitle">
                  {{ $t("crossConnects.redundancyActivationCosts") }}
                </div>
                <div class="flex-container text-style">
                  <span>{{ $t("crossConnects.activationPrice") }}</span>
                  <span class="second-item" style="font-weight: 400">
                    € {{ datacenterStore.nrc }}</span
                  >
                  <span>{{ $t("crossConnects.monthlyPrice") }}</span>
                  <span style="font-weight: 400">
                    € {{ datacenterStore.mrc }}</span
                  >
                </div>
                <div class="separator"></div>
                <div class="sectionTitle">
                  {{ $t("crossConnects.aendbend") }}*
                </div>
                <div class="cards-container-mobile">
                  <AEndComponent
                    :form-content="datacenterStore.raend"
                    :selectedDatacenterRoomsRA="selectedDatacenterRoomsA"
                    :validation-initiated="
                      datacenterStore.isValidationCheckInitiated
                    "
                    :redundant="true"
                  />
                  <div class="arrow-container">
                    <HorisontalArrow class="arrow-icon" />
                  </div>
                  <BEndComponentLocal
                    :form-content="datacenterStore.rbend"
                    :selectedDatacenterRoomsRB="selectedDatacenterRoomsA"
                    :validation-initiated="
                      datacenterStore.isValidationCheckInitiated
                    "
                    :attachment="datacenterStore.attachment_rbend"
                    :redundant="true"
                    @update-room="handleRoomSelectionBInContainer"
                    @update-island="handleIslandSelectionAInContainer"
                    @update-rack="handleRackSelectionBInContainer"
                    @toggle-changed="handleToggleChangedInsideCard"
                    @update-file="handleFileChange"
                  />
                </div>
                <div
                  v-if="!datacenterStore.rbend.is_my_rack"
                  class="subtitle-container-mobile"
                >
                  <div class="subtitle-text">
                    {{ $t("crossConnects.infoLOAFiles") }}
                  </div>
                  <div class="description-text">
                    {{ $t("crossConnects.authLetter") }}
                  </div>
                </div>
                <div
                  v-if="!datacenterStore.rbend.is_my_rack"
                  class="subtitle-container-mobile"
                >
                  <div class="subtitle-text">
                    {{ $t("crossConnects.reqLOAFiles") }}
                  </div>
                  <div class="description-text">
                    <p style="margin-top: 1rem">
                      {{ $t("crossConnects.reqLOAtext1") }}
                    </p>
                    <div>
                      {{ $t("crossConnects.reqLOAtext2") }}
                      <ul class="nested-list">
                        <li>
                          {{ $t("crossConnects.nameof") }}
                          <span class="highlight">{{
                            $t("crossConnects.society")
                          }}</span
                          >{{ $t("crossConnects.thatLOADeclares") }}
                        </li>
                        <li>
                          {{ $t("crossConnects.indicationAndPlacement") }}
                          <span class="highlight">B–END</span>
                          {{ $t("crossConnects.andOf") }}
                          <span class="highlight">{{
                            $t("crossConnects.port")
                          }}</span>
                          {{ $t("crossConnects.inWhichConnectionTerminated") }}
                        </li>
                        <li>
                          {{ $t("crossConnects.30daysValidation") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
            </div>
            <div v-else-if="currentStep === 4">
                <div class="sectionTitle" style="margin-top: 2rem">
                  {{ $t("crossConnects.otherInfo") }}
                </div>
              <div
                class="cards-container-mobile"
                style="text-align: left; border-radius: 4px 4px 0px 0px"
              >
                <div class="selectable-fields" style="padding: 0">
                  <div class="field-title">{{ $t("crossConnects.order") }}</div>
                  <div class="field">
                    <input
                      type="text"
                      name="card1-input3"
                      id="card1-input3"
                      v-model="datacenterStore.purchase_order"
                      class="input-element-mobile"
                    />
                  </div>
                  <div class="field-title">
                    <label>
                      {{ $t("crossConnects.notesAndTechDetails") }}
                    </label>
                    <div>
                      <small style="font-size: 12px">{{
                        $t("fields.maxlength", { len: "255" })
                      }}</small>
                    </div>
                  </div>
                  <div class="field">
                    <textarea
                      type="text"
                      name="notes"
                      id="notes"
                      v-model="datacenterStore.description"
                      class="text-area-element"
                      maxlength="255"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class="sectionTitle">
                {{ $t("crossConnects.crossConnectReferrer") }}
              </div>
              <div class="cards-container2">
                <MyCrossRefTicket :is-mobile="isMobile" />
                <div class="separator"></div>
              </div>
            </div>
          </div>
        </div>
        <ButtonsGroupComponent
          v-show="
            !datacenterStore.isCartItem
              ? datacenterStore.xConInfo.length
              : datacenterStore.isCartItem
          "
          :currentStep="currentStep"
          :isMobile="isMobile"
          :isFromValidation="datacenterStore.isFromvalidation"
          @step-button-click="goToStep"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/Global.scss";
@import "@/assets/scss/RefTicket.scss";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/CrossConnect.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.input-element::placeholder {
  color: #8895a7;
  font-family: OpenSansRegular;
  padding-left: 1rem;
}

.text-area-element::placeholder {
  color: #8895a7;
  font-family: OpenSansRegular;
  padding: 1rem;
}

.button-stylizing {
  margin-top: 2rem;
}
.input-element-mobile {
  padding-left: 1rem;
  width: 100%;
  height: 60px;
  background: #eef1f4 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #b8c4ce;
}
.cards-container2 {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Adjust the gap between rows as needed */
}

.selectable-fields2 {
  padding: 0rem;
  display: flex;
  gap: 1rem;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%; /* Ensure it takes the full width within the container */
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure it takes the full width within the container */
  justify-content: space-between;
}
</style>
