<template>
  <div id="PageTable">
    <MyBanner text="" />
    <MyHeaderPage :title="$t('orders.title')" subtitle="" />
    <br />
    <div class="contentPage">
      <div class="mdl-grid" style="align-items: baseline">
        <div class="mdl-cell mdl-cell--4-col mdl-cell--12-col-phone">
          <div class="cdl-input">
            <input type="date" class="not-empty" v-model="dateFrom" />
            <label>{{ $t("filter.from") }}</label>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col mdl-cell--12-col-phone">
          <div class="cdl-input">
            <input type="date" class="not-empty" v-model="dateTo" />
            <label>{{ $t("filter.to") }}</label>
          </div>
        </div>
        <div
          v-if="!IsMobile"
          class="mdl-cell mdl-cell--2-col mdl-cell--2-offset"
        >
          <div class="cdlButtonGreen" style="width: auto">
            <a style="color: white" @click="setFilter()">
              {{ $t("filter.filter") }}
            </a>
          </div>
        </div>
        <div v-else class="mdl-cell mdl-cell--12-col">
          <div class="cdlButtonGreen" style="width: auto">
            <a style="color: white" @click="setFilter()">
              {{ $t("filter.filter") }}
            </a>
          </div>
        </div>
      </div>

      <div id="InvoiceList" class="tableList">
        <table>
          <thead v-if="!IsMobile">
            <tr>
              <th>{{ $t("orders.submission_date") }}</th>
              <th>{{ $t("orders.category") }}</th>
              <th>{{ $t("orders.kit_title") }}</th>
              <th>{{ $t("orders.order_number") }}</th>
              <th>{{ $t("orders.state") }}</th>
              <th colspan="2"></th>
            </tr>
          </thead>
          <tbody v-if="!IsMobile">
            <tr v-if="!viewLoader">
              <td colspan="7">
                <MyLoading :viewLoader="viewLoader" />
              </td>
            </tr>
            <tr>
              <td colspan="7" style="text-align: center" v-if="orderFilter.length == 0 && viewLoader">
                <span>{{ $t("orders.noOrders") }}</span>
              </td>
            </tr>
            <tr v-for="(order, index) in orderFilter" :key="index" :class="{ odd: index % 2 }">
              <td>{{ getDate(order.submission_date) }}</td>
              <td>
                {{ order.category }} 
                <small
                    v-if="order.category === 'XCONNECT'"
                    v-show="
                      order.category_specific_fields.is_redundant &&
                      order.category_specific_fields.master_id
                    "
                    style="
                      text-transform: uppercase;
                      background-color: bisque;
                      padding: 5px;
                      border-radius: 5px;
                    "
                    >{{ $t("crossConnects.ridondanza") }}</small
                  >
              </td>
              <td>{{ order.kit_title }}</td>
              <td>{{ order.order_number }}</td>
              <td>{{ order.state }}</td>
              <td>
                <div class="cdlButton" @click="getOrderDetail(order.id)">
                  {{ $t("orders.details") }}
              </div>
              </td>
              <td>
                <div class="contentSvg" :class="{ noDownload: order.state != 'EVASO' }" v-tooltip:top.tooltip="order.state != 'EVASO' ? $t('orders.noPdf') : ''">
                  <DownloadPdf />                
                </div>
                <div class="contentSvg" @click="downloadPdf(order.id, order.order_number.replace('/', '_'))" :class="'icon-loader-' + order.id" v-if="order.state == 'EVASO'">
                  <DownloadPdf />          
                </div>    
                <div class="contentLoader" :class="'loader-' + order.id" style="display: none">
                  <MyLoading :viewLoader="false" />
                </div>  
              </td>
            </tr>
          </tbody>
          <tbody v-if="IsMobile">
            <tr v-if="!viewLoader">
              <td colspan="7">
                <MyLoading :viewLoader="viewLoader" />
              </td>
            </tr>
            <MobileCard
              class="philange"
              v-for="(order, index) in orderFilter" :key="index"
              :item="order"
              translation-path="orders"
              type="orders"
              :lang="this.$i18n.locale"
              :titles="['submission_date', 'category', 'kit_title', 'order_number', 'state']"
            >
              <template #url-label>
                <div class="contentSvg" :class="{ noDownload: order.state != 'EVASO' }" v-tooltip:top.tooltip="order.state != 'EVASO' ? $t('orders.noPdf') : ''">
                  <DownloadPdf /> 
                  <br /><br />               
                </div>
                <div class="contentSvg" @click="downloadPdf(order.id, order.order_number.replace('/', '_'))" :class="'icon-loader-' + order.id" v-if="order.state == 'EVASO'">
                  <DownloadPdf /> 
                  <br /><br />            
                </div>    
                <div class="contentLoader" :class="'loader-' + order.id" style="display: none">
                  <MyLoading :viewLoader="false" />
                </div>  
                <div class="cdlButton" @click="getOrderDetail(order.id)">
                  {{ $t("orders.details") }}
                </div></template
              >
            </MobileCard>
          </tbody>
        </table>
      </div>
      <div v-if="generalError != ''">
          <p class="error" style="text-align: center" v-html="generalError"></p>
        </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import moment from "moment";
// import { useFilterStore } from "@/stores/FilterStore";
import DownloadPdf from "@/components/svg/DownloadPdf.vue";
import MobileCard from "@/components/Cards/MobileCard.vue";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "OrdersPage",
  components: {
    MyBanner,
    MyHeaderPage,
    DownloadPdf,
    MobileCard,
    MyLoading
  },
  data() {
    return {
      IsMobile: this.$IsMobile(),
      dateFrom: moment().subtract(6, "months").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
      orderFilter: [],
      orders: [],
      viewLoader: false,
      viewError: false,
      generalError: ""
    };
  },
  methods: {
    setFilter() {
      this.orderFilter = [];
      this.viewLoader = false;
      let date = "";
      this.orders.forEach((elm, k) => {
        date = moment(elm.submission_date).format("YYYY-MM-DD");
        if( 
          (moment(date).isAfter(this.dateFrom) && moment(date).isBefore(this.dateTo)) || 
          moment(date).isSame(this.dateFrom) || moment(date).isSame(this.dateTo) ) {
          this.orderFilter.push(elm);
        }
        if( this.orders.length -1 == k ) {
          this.viewLoader = true;
        }
      });
    },
    getOrders() {
      this.viewLoader = false;
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      const self = this;

      this.axios
        .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "sales/v1/order", {
          headers: he,
        })
        .then((response) => {
          console.log(response);
          this.orders = response.data;
          this.orderFilter = response.data;
          this.setFilter();
          this.viewLoader = true;
        })
        .catch(function (err) {
          if (err.response.data.message == "invalid token") {
            return self.$router.push({ name: "MyLogin" });
          }
          self.viewLoader = true;
          self.generalError = err.response
            ? err.response.data.message
            : err.message;

        });
    },
    getDate(date) {
      return moment(date).format("DD/MM/YYYY", this.$i18n.locale, true);
    },
    getOrderDetail(orderId) {
      return this.$router.push({
        name: 'OrderDetails' + this.$i18n.locale,
        params: {
          order_id: orderId
        },
      });
    },
    downloadPdf(order_id, serialnumber = "doc") {
      console.log(serialnumber);

      document.getElementsByClassName('icon-loader-' + order_id)[0].style.display = "none";
      document.getElementsByClassName('loader-' + order_id)[0].style.display = "block";

      this.axios
        .get(
          `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}sales/v1/pdf/${
            order_id
          }`,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.$keycloak.idToken,
            },
            responseType: "blob",
          }
        )
        .then((res) => res.data.arrayBuffer())
        .then((response) => {
          this.viewLoader = true;
          const file = new Blob([response], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = serialnumber + ".pdf";
          link.click();
          document.getElementsByClassName('icon-loader-' + order_id)[0].style.display = "block";
          document.getElementsByClassName('loader-' + order_id)[0].style.display = "none";
        })
        .catch((err) => {
          this.viewLoader = true;
          console.error(err);
        });

      // let he = {
      //   Accept: "application/json",
      //   Authorization: "Bearer " + this.$keycloak.idToken,
      // };

      // this.axios
      //   .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "sales/v1/test-pdf/" + order_id, {
      //     headers: he,
      //   })
      //   .then((response) => {
      //     console.log(response);
      //     document.getElementsByClassName('icon-loader-' + order_id)[0].style.display = "block";
      //     document.getElementsByClassName('loader-' + order_id)[0].style.display = "none";
      //   })

    }

  },
  mounted() {
    window.scrollTo(0, 0);
    this.getOrders();
  },
};
</script>
