<template>
  <div class="card-validation">
    <div
      class="cardTitle"
      style="text-align: center; font-family: MontserratMedium"
    >
      {{ title }}
    </div>
    <div class="divider" style="margin-left: 2rem"></div>
    <div
      v-if="isLoaProvided"
      class="card-validation-grid"
      style="margin-left: 2rem"
    >
      <div class="description-text">
        <span class="grid-value"> {{ $t("crossConnects.OpticCdlan") }}</span>
      </div>
    </div>
    <div v-else class="card-validation-grid" style="margin-left: 2rem">
      <span class="grid-label" v-if="room" style="flex-grow: 2">{{
        $t("crossConnects.place")
      }}</span>
      <span class="grid-value" v-if="room">{{ room || "--" }}</span>
      <span class="grid-label" v-if="rack" style="flex-grow: 2">Rack</span>
      <span class="grid-value" v-if="rack">{{ rack || "--" }}</span>
      <span class="grid-label" v-if="devicePort" style="flex-grow: 2">{{
        $t("crossConnects.deviceAndPort")
      }}</span>
      <span class="grid-value" v-if="devicePort">{{ devicePort || "--" }}</span>
      <span class="grid-label" v-if="patchPanel" style="flex-grow: 2">{{
        $t("crossConnects.patchPannelMMR")
      }}</span>
      <span class="grid-value" v-if="patchPanel">{{ patchPanel || "--" }}</span>
      <span class="grid-label" v-show="isle" style="flex-grow: 2">{{
        $t("crossConnects.isle")
      }}</span>
      <span class="grid-value" v-show="isle">{{ isle || "--" }}</span>

      <span class="grid-label" v-show="building" style="flex-grow: 2">{{
        $t("crossConnects.building")
      }}</span>
      <span class="grid-value" v-show="building">{{ building || "--" }}</span>

      <span class="grid-label" v-show="wing" style="flex-grow: 2">{{
        $t("crossConnects.wing")
      }}</span>
      <span class="grid-value" v-show="wing">{{ wing || "--" }}</span>

      <span
        class="grid-label"
        v-if="attachmentName"
        style="flex-grow: 2; margin-right: 1rem"
        >{{ $t("crossConnects.uploadLOANoExp") }}</span
      >
      <span v-if="attachmentName" class="grid-value">
        <span
          style="cursor: pointer; text-decoration: underline"
          @click="this.downloadFile(attachment, attachmentName)"
          >{{ attachmentName }}</span
        >
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.descriptive-card {
  width: 32rem !important;
  text-align: left !important;
  margin-left: 0 !important;
}

.card-validation {
  overflow: hidden;
  min-width: 28rem;
  height: auto; // 214px
  border: 0.18rem $graySeven; // 3px
  border-radius: 0.6rem; // 10px
  background: $grayTwo;
  display: flex; // Set the display to flex
  flex-direction: column; // Stack content vertically
  color: #002e5f;
  max-width: 100%; // This ensures that the card does not overflow its container */
  max-height: 100%;
  padding: 2.5rem; // adjust padding as necessary
  margin: 1rem 5rem;

  .additional-content {
    flex-grow: 1; // This will allow this container to grow and extend the card downwards
  }

  .divider {
    margin: 2rem 0; // Adjusted margin to create spacing around the divider
    min-height: 0.2rem;
    background-color: $graySix;
    margin-right: -1rem;
    width: 401px;
    max-width: 401px; // Extend to the right edge of the card
  }
}

.card-validation-grid {
  display: grid;
  grid-template-columns: auto auto; /* Adjust the column sizes as needed */
  row-gap: 20px; /* Space between rows and columns */
  justify-items: flex-start;

  .grid-title {
    grid-column: 1 / -1; /* Span across all columns */
    text-align: center;
    font-family: MontserratMedium;
  }

  .grid-label {
    /* Styles for labels */
    text-align: right;
    color: #002e5f;
    font-family: MontserratMedium;
    font-size: 16px;
    font-weight: normal;
  }

  .grid-value {
    text-align: left;
  }
}
</style>

<script>
import {
  createAnchorForDownload,
  removeAnchor,
} from "@/services/DownloadFile.js";

export default {
  props: {
    title: String,
    room: String,
    rack: String,
    devicePort: String,
    patchPanel: String,
    isle: Number,
    wing: String,
    building: String,
    attachment: Number,
    attachmentName: String,
    isLoaProvided: Boolean,
  },
  // created() {
  //   console.log(this.$props);
  // },
  data() {
    return {
      asset: {},
    };
  },
  methods: {
    async downloadFile(file_id, filename) {
      try {
        const response = await this.axios({
          url: `${this.$MyCrypto(
            window.VUE_APP_MISTRA_URL,
            1
          )}files/v1/download/loa/${file_id}`,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.$keycloak.idToken,
          },
        });

        //create temp element to
        const anchorElement = createAnchorForDownload(
          response.data,
          true,
          filename
        );
        // simulate click
        anchorElement.click();
        // remove the element
        removeAnchor(anchorElement);
      } catch (error) {
        console.log("error: ", error);
      }
    },
  },
};
</script>
