
export function PriceClean(price) {
    if (typeof price != "string") {
        price = price.toFixed(2).replace(".", ",");
        return price;
    }
    if (typeof price == "string") {
        price = parseInt(price).toFixed(2).replace(".", ",");
        return price;
    }
    return price;
}